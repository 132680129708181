import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { usePrevious } from 'utils/hooks';
import { AcademicTerm, CreateCourseFormGlobalState } from 'types';

interface Props {
	setValue: UseFormSetValue<CreateCourseFormGlobalState>;
	termName?: string;
	academicTermId?: number;
	whenWillYouTeachThisCourse: string;
	academicTerms?: AcademicTerm[];
}

export function useTermInformation(props: Props): void {
	const { whenWillYouTeachThisCourse, termName, academicTermId, academicTerms, setValue } = props;
	const prevTermName = usePrevious(termName);

	/**
	 * Populate term options if they switch to / back from 'I don’t see my term'
	 */
	useEffect(() => {
		if (whenWillYouTeachThisCourse !== `I’m not sure`) {
			if (termName && termName !== `I don’t see my term`) {
				const term = academicTerms?.find((at) => at.label === termName);
				setValue('academicTermId', term?.id);
				setValue('termStartDate', term?.dates.firstDayOfClass);
				setValue('termEndDate', term?.dates.lastDayOfClass);
			} else if (termName === `I don’t see my term`) {
				/**
				 * Prevent this from getting reset when we are initializing the /review form
				 */
				if (prevTermName && prevTermName !== `I don’t see my term`) {
					setValue('termStartDate', '');
					setValue('termEndDate', '');
					setValue('academicTermId', undefined);
				}
			}
		} else {
			/**
			 * We should clear out `academicTermId` when we switch to `I'm not sure`
			 */
			if (academicTermId) {
				setValue('academicTermId', undefined);
			}
		}
	}, [whenWillYouTeachThisCourse, termName, academicTermId, academicTerms, setValue, prevTermName]);
}
