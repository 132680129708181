import React, { useMemo } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { FaBars, FaBookOpen } from 'react-icons/fa';
import { FaMagnifyingGlass } from 'react-icons/fa6';

import TableOfContents, { LinkComponentProps } from '@soomo/lib/components/ManifestTableOfContents';
import { ThemedTippy } from '@soomo/lib/components/pageElements/Text/components/WebtextTippy';

import Drawer from './Drawer';
import ToolsMenu from './ToolsMenu';
import { useNavbarContext } from './NavbarContext';
import { getNavbarStyle, NavbarStyle, ThemeSwitcher } from './theme';
import { useGetManifestQuery } from 'store/api';
import { usePreviewParams } from 'hooks';

const NavBar: React.FC<BoxProps> = (props) => {
	const { children } = props;
	const title = 'American Government: The Central Ideas';

	const {
		themeName,
		isAnyOpen,
		isContentsOpen,
		isSearchOpen,
		isToolsOpen,
		setIsOpen,
		isUV,
		renderComponentizedPreview,
		previewCourseId
	} = useNavbarContext();
	const { isCoverPage } = usePreviewParams();

	const { data: manifestData } = useGetManifestQuery({ courseId: previewCourseId, short: true });

	const tableOfContents = useMemo(() => {
		if (!manifestData) return null;

		return (
			<TableOfContents
				courseId={`${previewCourseId}`}
				manifest={manifestData}
				timeZone="America/New_York"
				LinkComponent={LinkComponent}
			/>
		);
	}, [manifestData, previewCourseId]);

	return (
		<Box as="nav" css={styles(getNavbarStyle(themeName))}>
			<div className="nav-bar-content" id="nav-bar-content">
				{children}

				{renderComponentizedPreview && (
					<Box className="nav-bar">
						{!isCoverPage && (
							<>
								<button
									className="nav-bar-item nav-bar-button"
									aria-expanded={isContentsOpen}
									onClick={() => setIsOpen('contents', !isContentsOpen)}
									tabIndex={isAnyOpen && !isContentsOpen ? -1 : 1}>
									<FaBookOpen />
									<span>Contents</span>
								</button>

								{!isUV && (
									<button
										className="nav-bar-item nav-bar-button"
										aria-expanded={isSearchOpen}
										onClick={() => setIsOpen('search', !isSearchOpen)}
										tabIndex={isAnyOpen && !isSearchOpen ? -1 : 1}>
										<FaMagnifyingGlass style={{ transform: 'rotateY(180deg)' }} />
									</button>
								)}
							</>
						)}

						<h1 className="nav-bar-item nav-bar-button nav-bar-title">
							<a>{title}</a>
						</h1>

						<button
							className="nav-bar-item nav-bar-button nav-bar-button-tools"
							aria-expanded={isToolsOpen}
							onClick={() => setIsOpen('tools', !isToolsOpen)}
							tabIndex={isAnyOpen && !isToolsOpen ? -1 : 1}>
							<FaBars />
							<span>Tools</span>
						</button>
					</Box>
				)}
			</div>

			{renderComponentizedPreview && (
				<>
					<Drawer open={isContentsOpen || isSearchOpen} side="left">
						{isContentsOpen && tableOfContents}
						{isSearchOpen && (
							<ul className="drawer-nav-list">
								<ThemeSwitcher />
							</ul>
						)}
					</Drawer>

					<Drawer open={isToolsOpen} side="right">
						<nav>
							<ul className="drawer-nav-list">
								<ToolsMenu />
								<ThemeSwitcher />
							</ul>
						</nav>
					</Drawer>
				</>
			)}
		</Box>
	);
};

const LinkComponent: React.FC<LinkComponentProps> = ({ page, className, children }) => {
	const { previewCourseId, setIsOpen } = useNavbarContext();
	const { setPageFamilyId } = usePreviewParams();

	const { data: manifest } = useGetManifestQuery({
		courseId: previewCourseId,
		short: true
	});

	const tooltipMessage = useMemo(() => {
		if (manifest) {
			const trial = manifest.toc.config.features.trial;

			if (trial) {
				return `Until your instructor status is verified, access is limited to ${trial.access_description}.`;
			}
		}

		return 'Loading...';
	}, [manifest]);

	if (!page?.is_available) {
		return (
			<ThemedTippy content={tooltipMessage}>
				<a className={className}>{children}</a>
			</ThemedTippy>
		);
	}

	return (
		<a
			className={className}
			onClick={() => {
				setPageFamilyId(page?.family_id || '');
				setIsOpen('contents', false);
			}}>
			{children}
		</a>
	);
};

const styles = (navbarStyle: NavbarStyle) => css`
	font-size: ${navbarStyle.fontSize};
	z-index: 1002;
	width: 100%;

	.nav-bar-content {
		z-index: 1003;
		position: fixed;
		width: 100%;
	}

	.nav-bar {
		height: ${navbarStyle.height};
		width: 100%;
		background-color: ${navbarStyle.backgroundColor};
		border-bottom: ${navbarStyle.borderBottomWidth}px solid ${navbarStyle.borderBottomColor};

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.nav-bar-item {
		font-size: ${navbarStyle.buttonStyle.fontSize};
		padding: 0 15px;
		height: 100%;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 12px;

		color: ${navbarStyle.buttonStyle.color};
		text-transform: ${!navbarStyle.isUV ? 'uppercase' : 'none'};

		svg {
			vertical-align: middle;
		}
	}

	h1.nav-bar-title {
		flex: 1;
		align-content: center;
		min-width: 0;

		${navbarStyle.isUV &&
		css`
			padding-left: 34px;
		`}

		color: ${navbarStyle.titleStyle.color};
		line-height: ${navbarStyle.titleStyle.lineHeight};
		font-size: ${navbarStyle.titleStyle.fontSize};
		font-family: ${navbarStyle.titleStyle.fontFamily};
		font-weight: ${navbarStyle.titleStyle.fontWeight};

		a {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.nav-bar-button {
		cursor: pointer;
		font-family: ${navbarStyle.buttonStyle.fontFamily};
		font-weight: ${navbarStyle.buttonStyle.fontWeight};
		text-align: center;

		${navbarStyle.isUV &&
		css`
			width: 109px;
			font-size: 16px;

			svg {
				display: none;
			}
		`}

		span {
			width: 100%;
			text-align: center;
		}
	}

	.nav-bar-button:hover {
		color: ${navbarStyle.buttonStyle.hover.color};
		background-color: ${navbarStyle.buttonStyle.hover.backgroundColor};
	}

	${navbarStyle.isUV &&
	css`
		.nav-bar-button.nav-bar-title:hover {
			color: inherit;
			background-color: inherit;
		}
	`}

	.nav-bar-button[aria-expanded='true'] {
		${!navbarStyle.isUV
			? css`
					border: 1px solid #979797;
					border-bottom: 2px solid ${navbarStyle.backgroundColor};
					border-top-right-radius: 2px;
					border-top-left-radius: 2px;
					margin-bottom: ${`-${navbarStyle.borderBottomWidth + 2}px`};
					z-index: 1001;
			  `
			: css`
					color: ${navbarStyle.buttonStyle.hover.color};
					background-color: ${navbarStyle.buttonStyle.hover.backgroundColor};
			  `}
	}

	.nav-bar-button[aria-expanded='true']:hover {
		${!navbarStyle.isUV &&
		css`
			border-bottom: 2px solid ${navbarStyle.buttonStyle.hover.backgroundColor};
		`}
	}

	@media (max-width: 650px) {
		h1.nav-bar-title {
			font-size: 15px;
		}

		.nav-bar-button-tools span {
			display: none;
		}

		${navbarStyle.isUV &&
		css`
			.nav-bar {
				height: 44px;
			}

			.nav-bar-item {
				padding: 0 10.5px;
			}

			.nav-bar-button {
				width: 42px;

				svg {
					display: block;
				}

				span {
					display: none;
				}
			}

			.nav-bar-button:not(.nav-bar-title) {
				justify-content: center;
			}

			h1.nav-bar-title {
				font-size: 16px;
				padding-left: 13px;
				padding-right: 0;

				a {
					text-overflow: clip;
				}
			}
		`}
	}

	@media (max-width: 400px) {
		.nav-bar-title {
			font-size: 0.8em;
		}

		.nav-bar-button > span {
			display: none;
		}
	}
`;

export default NavBar;
