import React, { FC } from 'react';

import { css } from '@emotion/react';

import { Text } from '@soomo/lib/components/pageElements';
import { CitationReferences } from '@soomo/lib/types';
import { TextElement as TextElementType } from '@soomo/lib/types/WebtextManifest';

interface Props {
	element: TextElementType;
	citationReferences?: CitationReferences;
	playMediaSnippet?: (familyId: string, start: string, end: string) => void;
	containerType?: string;
}

export const TextElement: FC<Props> = (props) => {
	const { element, citationReferences, playMediaSnippet, containerType } = props;

	const webtextPageContentContainer = document.getElementById('content-container-element');

	const tippyProps = {
		popperOptions: {
			modifiers: [
				// Flip tooltip automatically when it comes close to the edge page content container
				{ name: 'flip', options: { boundary: webtextPageContentContainer } }
			]
		}
	};

	const copyToClipboard = async (text: string): Promise<void> =>
		navigator.clipboard.writeText(text);

	const handleCitationClick = (_anchor: HTMLAnchorElement, target: HTMLAnchorElement) => {
		target.scrollIntoView();
		target.focus();
	};
	return (
		<Text
			css={textElementStyles.self}
			element={element}
			citationReferences={citationReferences}
			onCitationClicked={handleCitationClick}
			playMediaSnippet={playMediaSnippet}
			tippyProps={tippyProps}
			copyToClipboard={copyToClipboard}
			containerType={containerType}
			online
		/>
	);
};

const textElementStyles = {
	self: css`
		/**
		 * Spacing
		 *
		 * We are going to handle spacing with the "Spacer" component.  So we should remove
		 * margin-bottom from the last child in any "Text" component.
		 */
		.webtext-text > :last-child {
			margin-bottom: 0 !important;
		}
	`
};
