import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { HStack, Text } from '@chakra-ui/react';

import { DragAndDropItemProps } from 'components';
import { move, reorder } from 'utils';
import { Chapter, SelfServeConfig } from 'types';
import { useSelfServeConfig } from 'hooks/useSelfServeConfig';

interface DragAndDropProps {
	activeItems: Chapter[];
	setActiveItems: (items: Chapter[]) => void;
	removedItems: Chapter[];
	setRemovedItems: (items: Chapter[]) => void;
	setItems: (active: Chapter[], removed: Chapter[]) => void;
	onActiveItemActionClick?: (index: number) => void;
	onRemovedItemActionClick?: (index: number) => void;
	activeItemComponent: React.FC<DragAndDropItemProps>;
	removedItemComponent: React.FC<DragAndDropItemProps>;
	disabled?: boolean;
}

interface DragAndDropItemListProps {
	items: Chapter[];
	config: SelfServeConfig;
	ItemComponent: React.FC<DragAndDropItemProps>;
	onItemActionClick?: (index: number) => void;
	disabled?: boolean;
	droppableId?: string;
	removed?: boolean;
}

const SectionHeader: React.FC<{ title: string; helpText?: string }> = (props) => (
	<HStack borderBottom="1px solid black" mb="3" mt="6" justifyContent="space-between">
		<Text fontSize="lg" fontWeight="bold">
			{props.title}
		</Text>
		{props.helpText && (
			<Text fontSize="sm" fontStyle="italic">
				{props.helpText}
			</Text>
		)}
	</HStack>
);

const DragAndDropItemList: React.FC<DragAndDropItemListProps> = (props) => {
	const { items, config, ItemComponent, onItemActionClick, disabled, droppableId, removed } = props;

	return (
		<>
			{items.map((chapter: Chapter, index: number) => {
				const chapterConfig = config.chapterConfig[chapter.chapterFamilyId];

				if (!removed && index === 0 && config.anchorFirstChapter) {
					return (
						<ItemComponent
							key={`${droppableId}-${chapter.chapterFamilyId}`}
							chapterConfig={chapterConfig}
							slotIndex={index + 1}
							disabled={disabled}>
							{chapter.chapterNumber}: {chapter.chapterName}
						</ItemComponent>
					);
				}

				return (
					<Draggable
						key={`${droppableId}-${chapter.chapterFamilyId}`}
						draggableId={`${droppableId}-${chapter.chapterFamilyId}`}
						index={index}
						isDragDisabled={disabled || !chapterConfig?.draggable}>
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}>
								<ItemComponent
									chapterConfig={chapterConfig}
									actionButtonId={`${!removed ? 'remove' : 'unremove'}-chapter-${
										chapter.chapterNumber
									}`}
									actionAriaLabel={`${!removed ? 'Remove' : 'Unremove'} chapter ${
										chapter.chapterNumber
									}`}
									slotIndex={index + 1}
									disabled={disabled}
									onItemActionClick={() => onItemActionClick && onItemActionClick(index)}>
									{chapter.chapterNumber}: {chapter.chapterName}
								</ItemComponent>
							</div>
						)}
					</Draggable>
				);
			})}
		</>
	);
};

const ChaptersDragAndDrop: React.FC<DragAndDropProps> = (props) => {
	const {
		activeItems,
		setActiveItems,
		removedItems,
		setRemovedItems,
		setItems,
		onActiveItemActionClick,
		onRemovedItemActionClick,
		activeItemComponent,
		removedItemComponent,
		disabled
	} = props;

	const selfServeConfig = useSelfServeConfig();
	const activeDroppableId = 'active';
	const removedDroppableId = 'removed';

	function onDragEnd(result: DropResult) {
		const { source, destination } = result;

		const chapterFamilyId = result.draggableId.replace(`${source.droppableId}-`, '');
		const chapterConfig = selfServeConfig.chapterConfig[chapterFamilyId];

		const badMove =
			chapterConfig.removable == false &&
			source.droppableId === 'active' &&
			destination?.droppableId === 'removed';

		if (!destination || badMove) {
			return;
		}

		if (source.droppableId === destination?.droppableId) {
			if (source.droppableId === removedDroppableId) {
				setRemovedItems(reorder(removedItems, source.index, destination.index));
			} else {
				setActiveItems(reorder(activeItems, source.index, destination.index));
			}
		} else {
			const sourceList = source.droppableId === activeDroppableId ? activeItems : removedItems;
			const destList = destination.droppableId === activeDroppableId ? activeItems : removedItems;
			const result = move(sourceList, destList, source, destination);
			setItems(result[activeDroppableId], result[removedDroppableId]);
		}
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<SectionHeader
				title="Chapters"
				helpText="Drag and drop to reorder. Grey chapters can't be dragged."
			/>

			<Droppable droppableId={activeDroppableId}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{activeItems.length === 0 && (
							<Text fontStyle="italic" fontSize="sm" p="8" border="1px dashed black">
								[[Click the restore icon to make a chapter active again...]]
							</Text>
						)}

						<DragAndDropItemList
							items={activeItems}
							config={selfServeConfig}
							onItemActionClick={onActiveItemActionClick}
							ItemComponent={activeItemComponent}
							disabled={props.disabled}
							droppableId={activeDroppableId}
						/>

						{provided.placeholder}
					</div>
				)}
			</Droppable>

			<SectionHeader title="Removed Chapters" />

			<Droppable droppableId={removedDroppableId}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{removedItems.length === 0 && (
							<Text fontStyle="italic" fontSize="sm" p="8" border="1px dashed black">
								{!disabled
									? 'Click the trash icon to remove a chapter from your course.'
									: 'No chapters have been removed.'}
							</Text>
						)}

						<DragAndDropItemList
							items={removedItems}
							config={selfServeConfig}
							onItemActionClick={onRemovedItemActionClick}
							ItemComponent={removedItemComponent}
							disabled={props.disabled}
							droppableId={removedDroppableId}
							removed
						/>

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default ChaptersDragAndDrop;
