import { createApi } from '@reduxjs/toolkit/query/react';

import { FamilyId, Manifest, Page } from '@soomo/lib/types/WebtextManifest';

import { baseQueryWithAuthCheck } from 'store/api';

export const webtextApi = createApi({
	reducerPath: 'webtextApi',
	baseQuery: baseQueryWithAuthCheck,
	endpoints: (builder) => ({
		getManifest: builder.query<Manifest, { courseId: number; short: boolean, sessionId?: string }>({
			query: ({ courseId, short }) =>
				`/api/courses/v1/webtext/manifest?course_id=${courseId}&short=${short}`
		}),
		getPage: builder.query<Page, { courseId: number; pageFamilyId: FamilyId }>({
			query: ({ courseId, pageFamilyId }) =>
				`/api/courses/v1/webtext/page_manifest?course_id=${courseId}&page_family_id=${pageFamilyId}`
		})
	})
});

export const { useGetManifestQuery, useGetPageQuery } = webtextApi;
