import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HStack, Text } from '@chakra-ui/react';

import { useAppSelector } from 'store';
import { selectGlobalState, selectSchool } from 'store/slices/createCourseFormSlice';
import { NavigationDrawer } from 'components';
import Navbar from 'components/webtext/Navbar';
import PreviewNavigation from 'components/PreviewNavigation';
import { isComponentizedPreviewEnabledFor } from 'utils';

const Navigation: React.FC = () => {
	const location = useLocation();

	const { courseName, courseSectionNumber, webtext } = useAppSelector(selectGlobalState);
	const schoolId = useAppSelector(selectSchool).id;

	const [isPreview, setIsPreview] = useState(
		location.pathname.includes('/preview') || location.pathname === '/'
	);

	useEffect(() => {
		setIsPreview(location.pathname.includes('/preview') || location.pathname === '/');
	}, [location]);

	const getTitle = () => {
		if (isPreview) {
			return webtext;
		}
		return `Draft Course: ${courseName} ${courseSectionNumber}`;
	};

	if (isPreview) {
		if (!isComponentizedPreviewEnabledFor(schoolId)) {
			return (
				<Navbar>
					<PreviewNavigation />
				</Navbar>
			);
		}

		return null;
	}

	return (
		<HStack
			as="nav"
			background="background.500"
			p="2"
			px="5"
			borderColor="border.100"
			borderBottomWidth="1px">
			<Text fontWeight="bold" fontSize="lg" flex="1">
				{getTitle()}
			</Text>

			<NavigationDrawer>Courses</NavigationDrawer>
		</HStack>
	);
};

export default Navigation;
