import * as Yup from 'yup';

import { CreateCourseFormGlobalState } from './';

export const REQUIRED_FIELD_MESSAGE = 'Please complete this field to proceed';

export const CreateModalFormSchema: Yup.SchemaOf<Partial<CreateCourseFormGlobalState>> = Yup.object().shape({
	courseName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
	webtext: Yup.number().required(REQUIRED_FIELD_MESSAGE),
	courseSectionNumber: Yup.string().required(REQUIRED_FIELD_MESSAGE),
	whenWillYouTeachThisCourse: Yup.string().required(REQUIRED_FIELD_MESSAGE),
	academicTermId: Yup.number(),
	exampleTermName: Yup.string(),
	privateTermName: Yup.string(),
	termName: Yup.string(),
	termStartDate: Yup.string(),
	termEndDate: Yup.string(),
	///
	firstName: Yup.string(),
	lastName: Yup.string(),
	email: Yup.string(),
	schoolName: Yup.string(),
	estimatedNumberOfStudents: Yup.string(),
	studentAccessDate: Yup.string(),
	willThisCourseBeIntegratedWithAnLMS: Yup.string(),
	whichLMS: Yup.string(),
	pointsPerChapter: Yup.number(),
	willYouAssignDueDates: Yup.string(),
	willYouAssignAPenaltyPeriod: Yup.string(),
	note: Yup.string()
});

export const CreateCourseFromSchema: Yup.SchemaOf<CreateCourseFormGlobalState> = Yup.object().shape(
	{
		// yourInformation
		firstName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		lastName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		email: Yup.string().email('Invalid email').required(REQUIRED_FIELD_MESSAGE),
		schoolName: Yup.string().required(REQUIRED_FIELD_MESSAGE),

		// basicCourseDetails
		courseName: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		webtext: Yup.number().required(REQUIRED_FIELD_MESSAGE),
		courseSectionNumber: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		estimatedNumberOfStudents: Yup.string().required(REQUIRED_FIELD_MESSAGE),

		// termInformation
		studentAccessDate: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		whenWillYouTeachThisCourse: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		academicTermId: Yup.number(),
		exampleTermName: Yup.string().when('whenWillYouTeachThisCourse', {
			is: `I’m not sure`,
			then: Yup.string().required(),
			otherwise: Yup.string()
		}),
		privateTermName: Yup.string().when('termName', {
			is: 'I don’t see my term',
			then: Yup.string().required(REQUIRED_FIELD_MESSAGE)
		}),
		termName: Yup.string().when('academicTermId', {
			is: undefined,
			then: Yup.string(),
			otherwise: Yup.string().when('whenWillYouTeachThisCourse', {
				is: 'I know the exact term',
				then: Yup.string().required(REQUIRED_FIELD_MESSAGE)
			})
		}),
		termStartDate: Yup.string().required(REQUIRED_FIELD_MESSAGE),
		termEndDate: Yup.string().when('whenWillYouTeachThisCourse', {
			is: 'I know the exact term',
			then: Yup.string().required(REQUIRED_FIELD_MESSAGE)
		}),

		// integrations
		willThisCourseBeIntegratedWithAnLMS: Yup.string()
			.matches(/(Yes|No)/, REQUIRED_FIELD_MESSAGE)
			.required(REQUIRED_FIELD_MESSAGE),
		whichLMS: Yup.string().when('willThisCourseBeIntegratedWithAnLMS', {
			is: 'Yes',
			then: Yup.string().required(REQUIRED_FIELD_MESSAGE),
			otherwise: Yup.string()
		}),
		pointsPerChapter: Yup.number().when('willThisCourseBeIntegratedWithAnLMS', {
			is: 'Yes',
			then: Yup.number().required(REQUIRED_FIELD_MESSAGE)
		}),
		willYouAssignDueDates: Yup.string().when('willThisCourseBeIntegratedWithAnLMS', {
			is: 'Yes',
			then: Yup.string().required(REQUIRED_FIELD_MESSAGE)
		}),
		willYouAssignAPenaltyPeriod: Yup.string().when('willYouAssignDueDates', {
			is: 'Yes',
			then: Yup.string().required(REQUIRED_FIELD_MESSAGE)
		}),
		note: Yup.string()
	}
);
