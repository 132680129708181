import {
	BaseQueryFn,
	createApi,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import {
	AcademicTermsResponse,
	SchoolsResponse,
	TextbooksResponse,
	IntakeResponse,
	CourseCreationRequest,
	CourseCreationResponse,
	CourseReviewResponse,
	Course,
	School,
	Textbook,
	AcademicTerm,
	Intake,
	TextbookChapter,
	TextbooksChaptersResponse,
	CourseReviewRequest,
	CoursesResponse,
	CourseSubmissionResponse,
	CourseAccessUrlResponse,
	TextbookReviewUrlResponse,
	UserInfoResponse,
	UserInfo
} from 'types';
import { API_BASE } from './index';

const API = {
	ACCESS_TOKEN: `${API_BASE}/signup/instructor/access_token`,
	LOGIN: `${API_BASE}/signup/instructor/login`,
	SCHOOLS: `${API_BASE}/signup/instructor/schools`,
	TEXTBOOKS: `${API_BASE}/signup/instructor/textbooks`,
	TEXTBOOKS_CHAPTERS: (textbookId: number): string =>
		`${API_BASE}/signup/instructor/textbooks/${textbookId}/chapters`,
	TEXTBOOK_REVIEW_URL: (textbookId: number): string =>
		`/signup/instructor/textbooks/${textbookId}/review_url`,
	ACADEMIC_TERMS: `${API_BASE}/signup/instructor/academic_terms`,
	INTAKE: `${API_BASE}/signup/instructor/intake`,
	COURSES: `${API_BASE}/signup/instructor/courses`,
	COURSE_CREATION: `${API_BASE}/signup/instructor/course_creation`,
	COURSE_REVIEW: (courseId: number): string =>
		`${API_BASE}/signup/instructor/courses/${courseId}/course_review`,
	COURSE_SUBMISSION: (courseId: number): string =>
		`/signup/instructor/courses/${courseId}/course_submission`,
	COURSE_ACCESS_URL: (courseId: number): string =>
		`/signup/instructor/courses/${courseId}/access_url`,
	USER_INFO: `${API_BASE}/signup/instructor/user_info`,
	ACTION_CABLE: `${API_BASE}/cable`,
	ACCEPT_VERIFICATION: `${API_BASE}/signup/instructor/accept_verification`
};

export const baseQuery = fetchBaseQuery({
	baseUrl: API_BASE,
	prepareHeaders: (headers, { getState }) => {
		const { accessToken } = (getState() as RootState).contentSliceReducer;
		headers.set('Authorization', `Bearer ${accessToken}`);
		return headers;
	}
});

export const baseQueryWithAuthCheck: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
	async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);
		/**
		 * This is the way we have to check for token expiration at the moment
		 */
		if (result.meta?.response?.url.includes('session_missing')) {
			window.location.replace('/expired');
		}
		return result;
	};

export const selfServeApi = createApi({
	reducerPath: 'selfServeApi',
	baseQuery: baseQueryWithAuthCheck,
	tagTypes: ['Course'],
	endpoints: (builder) => ({
		login: builder.mutation<void, string>({
			query: (request) => ({
				url: API.LOGIN,
				method: 'POST',
				body: { email: request }
			})
		}),
		getSchools: builder.query<School[], string>({
			query: (schoolQuery) => `${API.SCHOOLS}?q=${schoolQuery}`,
			transformResponse: (response: SchoolsResponse) => response.schools
		}),
		getTextbooks: builder.query<Textbook[], void>({
			query: () => API.TEXTBOOKS,
			transformResponse: (response: TextbooksResponse) => response.textbooks
		}),
		getTextbooksChapters: builder.query<TextbookChapter[], number>({
			query: (textbookId) => API.TEXTBOOKS_CHAPTERS(textbookId),
			transformResponse: (response: TextbooksChaptersResponse) => response.chapters
		}),
		getTextbookReviewUrl: builder.query<string, number>({
			query: (textbookId) => API.TEXTBOOK_REVIEW_URL(textbookId),
			transformResponse: (response: TextbookReviewUrlResponse) => response.url
		}),
		getAcademicTerms: builder.query<AcademicTerm[], number>({
			query: (schoolId) => `${API.ACADEMIC_TERMS}?school_id=${schoolId}`,
			transformResponse: (response: AcademicTermsResponse) => response.academicTerms
		}),
		getIntake: builder.query<Intake, string>({
			query: (uuid) => `${API.INTAKE}?uuid=${uuid}`,
			transformResponse: (response: IntakeResponse) => response.intake
		}),
		getCourses: builder.query<Course[], void>({
			query: () => API.COURSES,
			transformResponse: (response: CoursesResponse) => response.courses
		}),
		updateCourseCreation: builder.mutation<number, CourseCreationRequest>({
			query: (body) => ({
				url: API.COURSE_CREATION,
				method: 'POST',
				body
			}),
			transformResponse: (response: CourseCreationResponse) => response.courseId
		}),
		getCourseReview: builder.query<CourseReviewResponse, number>({
			query: (courseId) => API.COURSE_REVIEW(courseId),
			providesTags: (result, error, arg) =>
				result ? [{ type: 'Course' as const, id: arg }, 'Course'] : ['Course']
		}),
		updateCourseReview: builder.mutation<CourseReviewResponse, CourseReviewRequest>({
			query: (request) => ({
				url: API.COURSE_REVIEW(request.courseId),
				method: 'POST',
				body: request.request
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.courseId }]
		}),
		updateCourseSubmission: builder.mutation<Course, number>({
			query: (courseId) => ({
				url: API.COURSE_SUBMISSION(courseId),
				method: 'POST'
			}),
			transformResponse: (response: CourseSubmissionResponse) => response.course
		}),
		getCourseAccessUrl: builder.query<string, number>({
			query: (courseId) => API.COURSE_ACCESS_URL(courseId),
			transformResponse: (response: CourseAccessUrlResponse) => response.url
		}),
		getUserInfo: builder.query<UserInfo, void>({
			query: () => API.USER_INFO,
			transformResponse: (response: UserInfoResponse) => response.user
		})
	})
});

export const {
	useLoginMutation,
	useGetSchoolsQuery,
	useGetTextbooksQuery,
	useGetTextbooksChaptersQuery,
	useGetTextbookReviewUrlQuery,
	useGetAcademicTermsQuery,
	useGetIntakeQuery,
	useLazyGetIntakeQuery,
	useGetCourseReviewQuery,
	useGetCoursesQuery,
	useLazyGetCoursesQuery,
	useGetCourseAccessUrlQuery,
	useGetUserInfoQuery,
	useLazyGetUserInfoQuery,
	useUpdateCourseCreationMutation,
	useUpdateCourseReviewMutation,
	useUpdateCourseSubmissionMutation
} = selfServeApi;

export const SELF_SERVE_API = API;
