import { useCallback, useEffect } from 'react';

export function useEscape(onEscape: () => void): void {
	const escFunction = useCallback((event) => {
		if (event.key === 'Escape') {
			onEscape();
		}
	}, [onEscape]);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);

		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, [escFunction]);
}
