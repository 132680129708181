import React, { createContext, useContext, useState } from 'react';

import { ThemeName } from '@soomo/lib/styles/themes';

import { useSpaceToNegate } from './useSpaceToNegate';

type DrawerKey = 'contents' | 'search' | 'tools';

export type DrawerSide = 'left' | 'right';

export type NavbarState = {
	themeName: ThemeName;
	isContentsOpen: boolean;
	isSearchOpen: boolean;
	isToolsOpen: boolean;
	isAnyOpen: boolean;
	isLeftSideOpen: boolean;
	isRightSideOpen: boolean;
	isUV: boolean;
	prevDrawerSide?: DrawerSide | null;
	setIsOpen: (NavbarKey: DrawerKey | null, open: boolean) => void;
	setTheme: (theme: ThemeName) => void;
	spaceToNegate: number;
	renderComponentizedPreview?: boolean;
	previewCourseId: number;
};

export const NavbarContext = createContext<NavbarState>({
	themeName: ThemeName.BLUE_VELVET,
	isContentsOpen: false,
	isSearchOpen: false,
	isToolsOpen: false,
	isAnyOpen: false,
	isLeftSideOpen: false,
	isRightSideOpen: false,
	isUV: false,
	setIsOpen: () => {
		console.log('default');
	},
	setTheme: () => {
		console.log('default');
	},
	spaceToNegate: 0,
	renderComponentizedPreview: false,
	previewCourseId: 0
});

export const useNavbarContext = () => useContext(NavbarContext);

const NavbarContextProvider: React.FC<{
	initThemeName: ThemeName;
	renderComponentizedPreview?: boolean;
	previewCourseId: number;
	children: React.ReactNode;
}> = ({ initThemeName, renderComponentizedPreview, previewCourseId, children }) => {
	const [themeName, setThemeName] = useState<ThemeName>(initThemeName);
	const [openDrawer, setOpenDrawer] = useState<DrawerKey | null>(null);

	const isContentsOpen = openDrawer === 'contents';
	const isSearchOpen = openDrawer === 'search';
	const isToolsOpen = openDrawer === 'tools';
	const isAnyOpen = isContentsOpen || isSearchOpen || isToolsOpen;
	const isLeftSideOpen = isContentsOpen || isSearchOpen;
	const isRightSideOpen = isToolsOpen;

	const setIsOpen = (key: DrawerKey | null, open: boolean) => {
		setOpenDrawer(open ? key : null);
	};

	const setTheme = (theme: ThemeName) => {
		setThemeName(theme);
	};

	const spaceToNegate = useSpaceToNegate(themeName);

	return (
		<NavbarContext.Provider
			value={{
				themeName,
				isContentsOpen,
				isSearchOpen,
				isToolsOpen,
				isAnyOpen,
				isLeftSideOpen,
				isRightSideOpen,
				isUV: themeName === ThemeName.UNIVERSAL_VELVET,
				setIsOpen,
				setTheme,
				spaceToNegate,
				renderComponentizedPreview,
				previewCourseId
			}}>
			{children}
		</NavbarContext.Provider>
	);
};

export default NavbarContextProvider;
