/**
 * Store theming information in this file for now, when this code is moved into libs we can fold this into the theming system
 */
import React from 'react';
import cn from 'classnames';

import { ThemeName } from '@soomo/lib/styles/themes';
import { useNavbarContext } from './NavbarContext';
import { usePreviewParams } from 'hooks';

export interface NavbarStyle {
	height: string;
	backgroundColor: string;
	borderBottomWidth: number;
	borderBottomColor: string;
	fontSize: string;
	drawerStyle: NavbarDrawerStyle;
	titleStyle: NavbarTitleStyle;
	buttonStyle: NavbarButtonStyle;
	isUV?: boolean;
}

interface NavbarTitleStyle {
	fontFamily: string;
	fontSize: string;
	fontWeight: number;
	lineHeight: string;
	color: string;
}

interface NavbarButtonStyle {
	fontFamily: string;
	fontSize: string;
	fontWeight: number;
	color: string;
	hover: {
		color: string;
		backgroundColor: string;
	};
}

interface NavbarDrawerStyle {
	color: string;
	backgroundColor: string;
	right: {
		width: string;
		padding: string;
	};
	left: {
		width: string;
		padding: string;
	};
	subMenu: {
		background: string;
		fontSize: string;
		fontWeight: number;
		hover: {
			color: string;
		};
	};
}

const baseNavBarStyle: NavbarStyle = {
	height: '40px',
	backgroundColor: '#000000',
	borderBottomWidth: 0,
	borderBottomColor: '#000000',
	fontSize: '16px',
	titleStyle: {
		fontFamily: `"Proxima Nova W15 Bold", sans-serif`,
		fontSize: '1em',
		fontWeight: 700,
		lineHeight: '40px',
		color: '#fefefe'
	},
	buttonStyle: {
		fontFamily: `"Helvetica Neue LT W01 55 Roman", sans-serif`,
		fontSize: '0.8em',
		fontWeight: 400,
		color: '#bcbec0',
		hover: {
			color: '#fefefe',
			backgroundColor: '#2f2f2f'
		}
	},
	drawerStyle: {
		color: '#b3b3b1',
		backgroundColor: '#121212',
		right: {
			width: '266px',
			padding: '15px'
		},
		left: {
			width: '266px',
			padding: '15px'
		},
		subMenu: {
			background: 'rgba(255, 255, 255, 0.03)',
			fontSize: '13px',
			fontWeight: 400,
			hover: {
				color: '#fff'
			}
		}
	},
	isUV: false
};

export const whiteVelvetNavBarStyle: NavbarStyle = {
	...baseNavBarStyle,
	backgroundColor: '#101118',
	borderBottomWidth: 2,
	borderBottomColor: '#634b7d',
	titleStyle: {
		...baseNavBarStyle.titleStyle,
		lineHeight: '38px'
	}
};

export const universalVelvetNavBarStyle: NavbarStyle = {
	...baseNavBarStyle,
	height: '52px',
	backgroundColor: '#ECE9E9',
	borderBottomColor: '#BEBEBE',
	borderBottomWidth: 1,
	isUV: true,
	buttonStyle: {
		...baseNavBarStyle.buttonStyle,
		color: '#000000',
		fontSize: '1em',
		fontFamily: `"Neue Haas Grotesk", sans-serif`,
		fontWeight: 500,
		hover: {
			color: '#FFFFFF',
			backgroundColor: '#5F01DF'
		}
	},
	titleStyle: {
		...baseNavBarStyle.titleStyle,
		color: '#000000',
		fontFamily: `"Neue Haas Grotesk", sans-serif`,
		fontSize: '20px',
		fontWeight: 700,
		lineHeight: '52px'
	},
	drawerStyle: {
		color: '#585858',
		backgroundColor: '#F8F8F8',
		right: {
			width: '299px',
			padding: '30px 36px 0 36px'
		},
		left: {
			width: '299px',
			padding: '0 0'
		},
		subMenu: {
			background: '#ECE9E9',
			fontSize: '16px',
			fontWeight: 400,
			hover: {
				color: '#000000'
			}
		}
	}
};

export default baseNavBarStyle;

export const getNavbarStyle = (themeName: ThemeName): NavbarStyle => {
	if (themeName === ThemeName.WHITE_VELVET) {
		return whiteVelvetNavBarStyle;
	}

	if (themeName === ThemeName.UNIVERSAL_VELVET) {
		return universalVelvetNavBarStyle;
	}

	return baseNavBarStyle;
};

export const ThemeSwitcher: React.FC = () => {
	const { setPageFamilyId } = usePreviewParams();
	const { setTheme, themeName } = useNavbarContext();

	return (
		<>
			<li style={{ marginTop: '2em' }}>✨💻dev tools🛠️✨</li>
			<li className={cn('drawer-nav-list-item-btn theme-s')}>
				<button onClick={() => setPageFamilyId('')}>Show cover page</button>
			</li>
			<li
				className={cn('drawer-nav-list-item-btn theme-s', {
					selected: themeName === ThemeName.UNIVERSAL_VELVET
				})}>
				<button onClick={() => setTheme(ThemeName.UNIVERSAL_VELVET)}>UNIVERSAL_VELVET</button>
			</li>
			<li
				className={cn('drawer-nav-list-item-btn theme-s', {
					selected: themeName === ThemeName.BLUE_VELVET
				})}>
				<button onClick={() => setTheme(ThemeName.BLUE_VELVET)}>BLUE_VELVET</button>
			</li>
			<li
				className={cn('drawer-nav-list-item-btn theme-s', {
					selected: themeName === ThemeName.WHITE_VELVET
				})}>
				<button onClick={() => setTheme(ThemeName.WHITE_VELVET)}>WHITE_VELVET</button>
			</li>
			<li
				className={cn('drawer-nav-list-item-btn theme-s', {
					selected: themeName === ThemeName.CORAL_VELVET
				})}>
				<button onClick={() => setTheme(ThemeName.CORAL_VELVET)}>CORAL_VELVET</button>
			</li>
		</>
	);
};
