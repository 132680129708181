import React from 'react';
import { FormControl, FormLabel, HStack, Radio, Text, VStack } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { formatDate } from 'utils';
import { ExampleTerms } from 'utils/content';
import { DatePickerControl, RadioGroupControl, SelectControl, TextControl } from 'components/forms';
import { useAppSelector } from 'store';
import { useGetAcademicTermsQuery } from 'store/api';
import { selectSchool } from 'store/slices/createCourseFormSlice';
import { CreateCourseFormGlobalState } from 'types';
import { useTermInformation } from 'utils/hooks';

const TermInformationForm: React.FC = () => {
	const schoolId = useAppSelector(selectSchool).id;

	const {
		register,
		formState: { errors },
		watch,
		control,
		setValue
	} = useFormContext<CreateCourseFormGlobalState>();

	const whenWillYouTeachThisCourse = watch('whenWillYouTeachThisCourse');
	const termName = watch('termName');
	const termStartDate = watch('termStartDate');
	const termEndDate = watch('termEndDate');
	const academicTermId = watch('academicTermId');

	const whenWillYouTeachThisCourseOptions = ['I know the exact term', `I’m not sure`];

	const { data: academicTerms } = useGetAcademicTermsQuery(schoolId, {
		refetchOnMountOrArgChange: true
	});

	const termNames = [
		...(academicTerms ? academicTerms.map((at) => at.label) : []),
		...(whenWillYouTeachThisCourse === 'I know the exact term' ? ['I don’t see my term'] : [])
	];

	const hasAcademicTerms = academicTerms && academicTerms?.length > 0;

	/**
	 * If the user has selected a preexisting academic term, then we will try to use it's
	 * `registration_begins` values as the default for `studentAccessDate`.
	 */
	const defaultStudentAccessDate = () => {
		if (academicTermId && academicTerms && academicTerms.find((at) => at.id === academicTermId)) {
			const selectedTerm = academicTerms.find((at) => at.id === academicTermId);

			if (selectedTerm?.dates.registrationBegins) {
				return selectedTerm.dates.registrationBegins;
			}
		}
	};

	useTermInformation({
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setValue,
		termName,
		academicTermId,
		whenWillYouTeachThisCourse,
		academicTerms: academicTerms
	});

	const { field: studentAccessDateField } = useController<CreateCourseFormGlobalState>({
		control,
		name: 'studentAccessDate'
	});

	const { field: whenWillYouTeachThisCourseField } = useController<CreateCourseFormGlobalState>({
		control,
		name: 'whenWillYouTeachThisCourse'
	});

	const { field: termStartDateField } = useController<CreateCourseFormGlobalState>({
		control,
		name: 'termStartDate'
	});

	const { field: termEndDateField } = useController<CreateCourseFormGlobalState>({
		control,
		name: 'termEndDate'
	});

	return (
		<>
			<DatePickerControl
				name="studentAccessDate"
				label="Student Access Date"
				field={studentAccessDateField}
				setValue={(value: string) => {
					setValue('studentAccessDate', value, { shouldValidate: true });
				}}
				error={errors.studentAccessDate?.message}
				defaultSelected={defaultStudentAccessDate()}
			/>

			<RadioGroupControl
				name="whenWillYouTeachThisCourse"
				label="When will you teach this course?"
				field={whenWillYouTeachThisCourseField}
				setValue={(value: string) => {
					setValue('whenWillYouTeachThisCourse', value, { shouldValidate: true });
				}}
				error={errors.whenWillYouTeachThisCourse?.message}>
				{whenWillYouTeachThisCourseOptions.map((o) => (
					<Radio key={o} colorScheme="blackAlpha" size="lg" value={o}>
						{o}
					</Radio>
				))}
			</RadioGroupControl>

			{whenWillYouTeachThisCourse === 'I know the exact term' && (
				<>
					{hasAcademicTerms && (
						<>
							<SelectControl
								name="termName"
								label="Term Options"
								selectProps={{
									...register('termName'),
									placeholder: 'Select your term'
								}}
								error={errors.termName?.message}>
								<option disabled hidden></option>
								{termNames.map((o) => (
									<option key={o} value={o}>
										{o}
									</option>
								))}
							</SelectControl>

							{termName && termName !== 'I don’t see my term' && (
								<HStack my="4" justifyContent="start">
									<FormControl w="initial" mr="6">
										<FormLabel>Term Start Date</FormLabel>
										<Text fontSize="lg">{termStartDate && formatDate(termStartDate)}</Text>
									</FormControl>
									<FormControl w="initial" mr="6">
										<FormLabel>Term End Date</FormLabel>
										<Text fontSize="lg">{termEndDate && formatDate(termEndDate)}</Text>
									</FormControl>
								</HStack>
							)}
						</>
					)}

					{(!hasAcademicTerms || termName === 'I don’t see my term') && (
						<VStack mb="6">
							<TextControl
								name="privateTermName"
								label="Term name"
								inputProps={{ ...register('privateTermName') }}
								error={errors.privateTermName?.message}
							/>

							<HStack my="4" w="100%">
								<DatePickerControl
									name="termStartDate"
									label="Term Start Date"
									field={termStartDateField}
									setValue={(value: string) => {
										setValue('termStartDate', value, { shouldValidate: true });
									}}
									formControlProps={{ w: 'initial', mr: '6', flex: '1', mb: '0' }}
									error={errors.termStartDate?.message}
								/>

								<Text
									alignSelf="center"
									textAlign="center"
									fontSize="lg"
									pt="7"
									ml="0"
									pr="4"
									marginInlineStart="0 !important">
									-
								</Text>

								<DatePickerControl
									name="termEndDate"
									label="Term End Date"
									field={termEndDateField}
									setValue={(value: string) => {
										setValue('termEndDate', value, { shouldValidate: true });
									}}
									formControlProps={{
										w: 'initial',
										mr: '6',
										flex: '1'
									}}
									error={errors.termEndDate?.message}
								/>
							</HStack>
						</VStack>
					)}
				</>
			)}

			{whenWillYouTeachThisCourse === `I’m not sure` && (
				<>
					<SelectControl
						name="exampleTermName"
						label="Select the term that best applies."
						selectProps={{
							...register('exampleTermName'),
							placeholder: 'Select your term'
						}}
						error={errors.exampleTermName?.message}>
						<option disabled hidden></option>
						{ExampleTerms.map((o) => (
							<option key={o} value={o}>
								{o}
							</option>
						))}
					</SelectControl>

					<DatePickerControl
						name="termStartDate"
						label="Approximate Term Start Date"
						field={termStartDateField}
						setValue={(value: string) => {
							setValue('termStartDate', value, { shouldValidate: true });
						}}
						error={errors.termStartDate?.message}
					/>
				</>
			)}
		</>
	);
};

export default TermInformationForm;
