import React from 'react';
import cn from 'classnames';
import { Box, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';

import { DrawerSide, useNavbarContext } from './NavbarContext';
import { getNavbarStyle, NavbarStyle } from './theme';

interface Props {
	open?: boolean;
	side: DrawerSide;
}

const Drawer: React.FC<Props & BoxProps> = (props) => {
	const { side } = props;

	const { themeName, spaceToNegate } = useNavbarContext();

	return (
		<Box css={styles(side, spaceToNegate, getNavbarStyle(themeName))}>
			<div
				className={cn('drawer-content', {
					open: props.open,
					right: side === 'right',
					left: side === 'left'
				})}
				aria-hidden={!props.open}
				{...{ inert: !props.open ? '' : undefined }}>
				{props.children}
			</div>
		</Box>
	);
};

const styles = (side: DrawerSide, spaceToNegate: number, navbarStyle: NavbarStyle) => css`
	z-index: 1000;
	position: fixed;
	top: ${spaceToNegate}px;
	bottom: 0;
	right: ${side === 'right' ? 0 : 'auto'};
	left: ${side === 'left' ? 0 : 'auto'};
	height: auto;
	overflow: auto;
	color: ${navbarStyle.drawerStyle.color};
	background: ${navbarStyle.drawerStyle.backgroundColor};

	${Number.isInteger(navbarStyle.borderBottomWidth) &&
	css`
		margin-top: -${navbarStyle.borderBottomWidth}px;
	`}

	.drawer-content {
		width: 0;
		transition: padding 0.1s ease, width 0.1s ease;
	}

	.drawer-content.open {
		padding: ${navbarStyle.drawerStyle[side].padding};
		width: ${navbarStyle.drawerStyle[side].width};
		border-top: 1px solid #979797;
	}

	.drawer-nav-list-item-btn {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		gap: 10px;

		font-size: ${navbarStyle.drawerStyle.subMenu.fontSize};
		${navbarStyle.isUV ? 'text-transform: none;' : 'text-transform: uppercase;'};
		padding: 10px 0;
	}

	.drawer-nav-list-item-btn:hover {
		color: ${navbarStyle.drawerStyle.subMenu.hover.color};
	}

	.drawer-nav-list-item-btn svg {
		font-size: 1.1em;
		color: #73757a;
	}

	.drawer-nav-list-item-btn.theme-s button {
		width: 100%;
		padding: 0.2em;
		border: 2px dotted gray;
		border-radius: 0.5em;
		margin: 1px;
	}

	.drawer-nav-list-item-btn.theme-s.selected button {
		border: 2px solid darkgray;
	}

	li {
		list-style: none;
	}
`;

export default Drawer;
