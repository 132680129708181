import React from 'react';
import cn from 'classnames';
import { Box, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';

import themes from '@soomo/lib/styles/themes';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';
import FontProvider from '@soomo/lib/styles/themes/FontProvider';

import Content from './Content';
import Navbar from './Navbar';
import { useNavbarContext } from './NavbarContext';
import PreviewNavigation from 'components/PreviewNavigation';
import Cover from './Cover';
import { useEscape } from './useEscape';
import { usePreviewParams } from 'hooks';

const Webtext: React.FC<BoxProps> = (props) => {
	const { pageFamilyId } = usePreviewParams();
	const { themeName, spaceToNegate, isAnyOpen, setIsOpen } = useNavbarContext();

	const theme = themes[themeName];

	const hideOverlay = () => {
		setIsOpen(null, false);
	};

	useEscape(hideOverlay);

	return (
		<>
			<ThemeProvider theme={theme}>
				<FontProvider />
				<Box css={styles(spaceToNegate)} {...props}>
					<Navbar>
						<PreviewNavigation />
					</Navbar>
					<div
						className="content-container"
						id="content-container-element"
						onClick={() => {
							hideOverlay();
						}}>
						{pageFamilyId ? <Content /> : <Cover />}
						<div className={cn('overlay', { open: isAnyOpen })}></div>
					</div>
				</Box>
			</ThemeProvider>
		</>
	);
};

const styles = (spaceToNegate: number) => css`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 0 !important;
	overflow: hidden;
	font-family: 'Neue Haas Grotesk', sans-serif;

	.content-container {
		margin-top: ${spaceToNegate}px;
		width: 100%;
		height: 100%;

		overflow-y: scroll;
	}

	.overlay {
		position: fixed;
		height: 0;
		width: 100%;
		height: 0;
		max-height: 100vh;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 2;
		cursor: pointer;

		transition: display 1s ease, opacity 1s ease;
		opacity: 0;
		overflow: hidden;
	}

	.overlay.open {
		height: auto;
		opacity: 1;
	}
`;

export default Webtext;
