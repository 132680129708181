import React from 'react';
import { Table, Tbody, Tr, Td, Text, VStack, Box, Icon, Stack } from '@chakra-ui/react';
import { FaLock } from 'react-icons/fa';
import { useFormContext } from 'react-hook-form';

import { DragAndDropItem } from 'components';
import { ChapterTableHead, ChapterTableRow } from 'components/controls';
import { Chapter, CreateCourseFormGlobalState } from 'types';
import { ChapterRow } from './types';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectSchool } from 'store/slices/createCourseFormSlice';
import {
	selectChapterOrganization,
	updateChapterOrganizationState
} from 'store/slices/chapterOrganizationSlice';

interface Props {
	locked?: boolean;
	readOnly?: boolean;
}

const ChapterTable: React.FC<Props> = (props) => {
	const { locked, readOnly } = props;

	const dispatch = useAppDispatch();
	const school = useAppSelector(selectSchool);
	const { watch } = useFormContext<CreateCourseFormGlobalState>();

	const willYouAssignDueDates = watch('willYouAssignDueDates');
	const willYouAssignAPenaltyPeriod = watch('willYouAssignAPenaltyPeriod');
	const willThisCourseBeIntegratedWithAnLMS = watch('willThisCourseBeIntegratedWithAnLMS');
	const pointsPerChapter = watch('pointsPerChapter');
	const academicTermId = watch('academicTermId');
	const termEndDate = watch('termEndDate');

	/**
	 * `maxDate` should only have a value when we have a `academicTermId`. Meaning they are using
	 * a preexisting academic term.
	 */
	const maxDate = academicTermId ? termEndDate : undefined;

	const chapterOrganization = useAppSelector(selectChapterOrganization);
	const { chapters } = chapterOrganization;

	const updateChapterState = (chapterNumber: number, update: Partial<Chapter>) => {
		const chapterIndex = chapters.findIndex((c) => c.chapterNumber === chapterNumber);
		const chaptersState = [...chapters];
		chaptersState[chapterIndex] = { ...chaptersState[chapterIndex], ...update };
		dispatch(updateChapterOrganizationState({ ...chapterOrganization, chapters: chaptersState }));
	};

	const transformRow = (row: Partial<Chapter>, index: number) => {
		return {
			chapterNumber: row.chapterNumber,
			chapterName: `${index + 1}: ${row.chapterName}`,
			points: row.points,
			dueDate: row.dueDate,
			dueTime: row.dueTime,
			penaltyPercent: row.penaltyPercent,
			penaltyPeriod: row.penaltyPeriod
		} as ChapterRow;
	};

	if (locked) {
		return (
			<Box position="relative">
				<Box
					position="absolute"
					left="50%"
					top="45%"
					transform="translate(-50%)"
					background="background.700"
					zIndex="1"
					color="white"
					p="6"
					borderRadius="md">
					<VStack>
						<Icon as={FaLock} />
						<Text>Answer all yes/no questions above to unlock your due date customizations</Text>
					</VStack>
				</Box>
				<VStack align="stretch" backgroundColor="background.600" p="3" opacity="0.5">
					<Box borderBottom="1px solid black">
						<Text fontSize="md">Chapters</Text>
					</Box>
					{chapters.map((chapter, index) => (
						<DragAndDropItem key={`chapter-${index}`} icon={<FaLock />} disabled>
							{`${index + 1}: ${chapter.chapterName}`}
						</DragAndDropItem>
					))}
				</VStack>
			</Box>
		);
	}

	return (
		<>
			{!readOnly && (
				<>
					<Text mt="10" fontWeight="bold">
						Please submit your course below.
					</Text>
					<Text mb="6" fontStyle="italic" fontSize="15px">
						If you have determined your due dates, you can adjust them in the following table.
					</Text>
				</>
			)}

			<Stack
				justify="center"
				ml={[-8, 0]}
				mr={[-8, 0]}
				px={[1, 0]}
				borderWidth={[1, 0]}
				mb={[2, 0]}
				overflow="scroll">
				<Table
					variant="striped"
					size={readOnly ? 'md' : 'sm'}
					colorScheme="blackAlpha"
					mb="4"
					bg="white">
					<ChapterTableHead
						readOnly={readOnly}
						willYouAssignDueDates={willYouAssignDueDates}
						willYouAssignAPenaltyPeriod={willYouAssignAPenaltyPeriod}
						willThisCourseBeIntegratedWithAnLMS={willThisCourseBeIntegratedWithAnLMS}
					/>

					<Tbody>
						{chapters.map((row, rowIndex) => {
							return (
								<ChapterTableRow
									key={`row-${rowIndex}`}
									row={transformRow(row, rowIndex)}
									rowIndex={rowIndex}
									updateChapterState={updateChapterState}
									willYouAssignDueDates={willYouAssignDueDates}
									willYouAssignAPenaltyPeriod={willYouAssignAPenaltyPeriod}
									willThisCourseBeIntegratedWithAnLMS={willThisCourseBeIntegratedWithAnLMS}
									isLastRow={rowIndex === chapters.length - 1}
									readOnly={readOnly}
									timeZone={school.timeZone}
									maxDate={maxDate}
								/>
							);
						})}

						{!readOnly && (
							<Tr fontWeight="bold" borderBottom="1px solid black">
								<Td border="none">Total</Td>
								<Td border="none" textAlign="right">
									{Number(pointsPerChapter) * chapters.length}
								</Td>
								{willYouAssignDueDates === 'Yes' && <Td border="none" colSpan={6} />}
							</Tr>
						)}
					</Tbody>
				</Table>
			</Stack>

			{willYouAssignDueDates && (
				<Text mb="6" fontStyle="italic" fontSize="15px">
					* The time zone will be configured to match your{' '}
					{school.id !== 0 ? `school’s` : `browser’s`} time zone. If you need something different,
					please note it in the comments below.
				</Text>
			)}
		</>
	);
};

export default ChapterTable;
