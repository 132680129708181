import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Container } from '@chakra-ui/react';

import { Navigation } from 'components';
import { ExternalRedirect } from 'components/nav';
import { Review, UnderReview, Preview, Welcome, Error, Expired } from 'components/pages';
import { useBootIntercom } from 'hooks';
import { MARKETING_SITE_URL } from 'store/api';

function SelfServe(): JSX.Element {
	const location = useLocation();

	const showAppNavigation =
		!location.pathname.includes('welcome') &&
		!location.pathname.includes('error') &&
		!location.pathname.includes('expired');

	/**
	 * Boot Intercom when the page loads, this will happen once per mount since this component
	 * handles routing. We need to do this here so that Intercom boots after the user reloads the
	 * page or navigates directly to a page.
	 */
	useBootIntercom();

	/**
	 * Inform Intercom when the route changes
	 */
	useEffect(() => {
		window.Intercom('update');
	}, [location]);

	return (
		<>
			{showAppNavigation && (
				<header id="self-serve-header">
					<Navigation />
				</header>
			)}

			<Helmet>
				<title>Self-serve | Soomo Learning</title>
			</Helmet>

			<Switch>
				<Route path="/welcome">
					<Helmet>
						<title>Welcome | Soomo Learning</title>
					</Helmet>
					<Welcome />
				</Route>

				<Route path="/preview/:textbookId?/:pageFamilyId?">
					<Helmet>
						<title>Preview | Soomo Learning</title>
					</Helmet>
					<Preview />
				</Route>

				<Route path="/review/:courseId?">
					<Helmet>
						<title>Review | Soomo Learning</title>
					</Helmet>
					<Container maxW="container.md" centerContent p={4} pt={[8, 16]}>
						<Review />
					</Container>
				</Route>

				<Route path="/under-review/:courseId">
					<Helmet>
						<title>Under review | Soomo Learning</title>
					</Helmet>
					<Container maxW="container.md" centerContent p="4" pt="16">
						<UnderReview />
					</Container>
				</Route>

				<Route path="/error">
					<Helmet>
						<title>Uh oh | Soomo Learning</title>
					</Helmet>
					<Error />
				</Route>

				<Route path="/expired">
					<Helmet>
						<title>Session expired | Soomo Learning</title>
					</Helmet>
					<Expired />
				</Route>

				{/**
				 * Redirect un-handled routes to the marketing site
				 */}
				<ExternalRedirect path="*" link={MARKETING_SITE_URL || '/expired'} />
			</Switch>
		</>
	);
}

export default SelfServe;
