import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import { css } from '@emotion/react';
import { FaVolumeUp, FaPencilRuler, FaCog, FaQuestionCircle } from 'react-icons/fa';

import NavbarDropdownMenu from './NavbarDropdownMenu';

const ToolsMenu: React.FC = () => {
	const [readToMe, setReadToMe] = useState(false);
	const [helpOpen, setHelpOpen] = useState(false);

	/**
	 * styles for `.drawer-nav-list-item-btn` are handled in <Drawer />
	 */
	return (
		<>
			<Box as="li" css={switchStyle}>
				<NavbarDropdownMenu
					handle={
						<>
							<FaVolumeUp /> <span>Read to Me</span>
							<span
								className={cn('switch', { checked: readToMe })}
								aria-checked="false"
								aria-label="Read to me"
								role="checkbox"
								tabIndex={0}>
								<small></small>
							</span>
						</>
					}
					side="right"
					open={readToMe}
					setOpen={setReadToMe}
					hideToggle>
					Future speech settings
				</NavbarDropdownMenu>
			</Box>

			<li>
				<a className="drawer-nav-list-item-btn" href="#">
					<FaPencilRuler /> <span>Citation Guide</span>
				</a>
			</li>
			<li>
				<a className="drawer-nav-list-item-btn" href="#">
					<FaCog /> <span>Credits</span>
				</a>
			</li>
			<li>
				<NavbarDropdownMenu
					handle={
						<>
							<FaQuestionCircle /> <span>Help</span>
						</>
					}
					side="right"
					items={[
						{ title: 'Instructor Help Center', href: 'https://instructorhelp.soomolearning.com/en/' },
						{ title: 'Student Help Center', href: 'https://help.soomolearning.com/en/' },
						{ title: 'Contact Support', href: 'https://www.soomolearning.com/support' }
					]}
					open={helpOpen}
					setOpen={setHelpOpen}
				/>
			</li>
		</>
	);
};

const switchStyle = () => css`
	.switch {
		background-color: #949494;
		border: 1px solid #949494;
		position: relative;
		display: inline-block;
		box-sizing: content-box;
		overflow: visible;
		padding: 0px;
		margin: 0px;
		border-radius: 20px;
		cursor: pointer;
		box-shadow: #dfdfdf 0px 0px 0px 0px inset;
		transition: 0.3s ease-out all;
		-webkit-transition: 0.3s ease-out all;
		top: -1px;
		height: 12px;
		width: 24px;
		margin-left: 4px;
	}

	.switch.checked {
		background: #047da4;
		border-color: #047da4;
	}

	.switch small {
		background: #fff;
		border-radius: 100%;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
		width: 30px;
		height: 30px;
		position: absolute;
		top: 0px;
		left: 1px;
		width: 10px;
		height: 10px;
		margin-top: 1px;
		transition: 0.3s ease-out all;
		-webkit-transition: 0.3s ease-out all;
	}

	.switch.checked small {
		left: 12px;
	}
`;

export default ToolsMenu;
