import React, { forwardRef } from 'react';
import {
	Input,
	InputProps,
	Select,
	HStack,
	FormControl,
	FormLabel,
	FormControlProps,
	FormErrorMessage,
	InputGroup,
	InputRightElement
} from '@chakra-ui/react';
import { RiCalendar2Line } from 'react-icons/ri';
import { getYear, getMonth, parseISO, formatISO, isValid } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import { ControllerRenderProps } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

import { range, translateTimeZone } from 'utils';
import { useAppSelector } from 'store';
import { selectSchool } from 'store/slices/createCourseFormSlice';
import './DatePickerControl.css';
import { CreateCourseFormGlobalState } from 'types';

interface Props {
	name: string;
	label: string;
	error?: string;
	formControlProps?: FormControlProps;
	field: ControllerRenderProps<CreateCourseFormGlobalState>;
	setValue: (value: string) => void;
	defaultSelected?: string;
}

const DatePickerControl: React.FC<Props> = (props) => {
	const { name, label, error, formControlProps, field, setValue, defaultSelected } = props;

	const school = useAppSelector(selectSchool);

	const years = range(getYear(new Date()), getYear(new Date()) + 3);
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	const CustomInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
		/**
		 * Spread for removal, this is to support allowing a "default" selected date.
		 *
		 * Add for T-2699
		 */
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { value, ...propsWithoutValue } = props;

		const inputProps = !field.value ? propsWithoutValue : props;

		return (
			<InputGroup>
				<Input
					color={field.value ? 'initial' : 'transparent'}
					className="chakra-input-for-date-picker"
					placeholder="Click here to select a date"
					{...inputProps}
					ref={ref}
				/>
				<InputRightElement mt="1.5" onClick={props.onClick}>
					<RiCalendar2Line fontSize="1.25em" />
				</InputRightElement>
			</InputGroup>
		);
	});

	CustomInput.displayName = 'customInput';

	const setDate = (date: Date) => {
		setValue(formatISO(translateTimeZone(date, school.timeZone)));
	};

	/**
	 * If the field has a value and that value is a valid date then we return the field's value.
	 * (This is the value that comes from `react-hook-form`) If this is not the case we check to
	 * see if a `defaultSelected` prop was passed, if so we return the `defaultSelected` prop as a
	 * date.
	 *
	 * @returns Date | null
	 */
	const selectedValue = () => {
		if (field.value && isValid(parseISO(field.value as string))) {
			return parseISO(field.value as string);
		} else {
			if (defaultSelected) {
				return parseISO(defaultSelected);
			}
		}
		return null;
	};

	return (
		<FormControl id={name} marginBottom="4" isInvalid={!!error} {...formControlProps}>
			<FormLabel>{label}</FormLabel>
			<ReactDatePicker
				name={name}
				minDate={new Date()}
				autoFocus={false}
				renderCustomHeader={({ date, changeYear, changeMonth }) => (
					<HStack mx="4" my="1" display="flex" justifyContent="space-between">
						<Select
							id="date-picker-select-month"
							flex="3"
							variant="flushed"
							size="sm"
							value={months[getMonth(date)]}
							onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
							{months.map((option) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</Select>

						<Select
							id="date-picker-select-year"
							flex="2"
							variant="flushed"
							size="sm"
							value={getYear(date)}
							onChange={({ target: { value } }) => changeYear(Number(value))}>
							{years.map((option) => (
								<option key={option} value={option}>
									{option}
								</option>
							))}
						</Select>
					</HStack>
				)}
				selected={selectedValue()}
				onChange={(date: Date) => {
					if (date) {
						setDate(date);
					} else {
						setValue('');
					}
				}}
				onSelect={(date) => {
					if (date) {
						setDate(date);
					}
				}}
				onBlur={field.onBlur}
				customInput={<CustomInput />}
			/>
			{error && <FormErrorMessage>{error}</FormErrorMessage>}
		</FormControl>
	);
};

export default DatePickerControl;
