import React, { useEffect } from 'react';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';

import { useAppDispatch } from 'store';
import { useGetSchoolsQuery } from 'store/api';
import { TextControl, TypeaheadControl } from 'components/forms';
import { CreateCourseFormGlobalState } from 'types';
import { updateSchool } from 'store/slices/createCourseFormSlice';

const YourInformationForm: React.FC = () => {
	const dispatch = useAppDispatch();

	const {
		register,
		formState: { errors },
		setValue,
		watch
	} = useFormContext<CreateCourseFormGlobalState>();

	const schoolName = watch('schoolName');
	const [debouncedSchoolName, setDebouncedSchoolName] = useDebounceValue(schoolName, 500);
	const { data } = useGetSchoolsQuery(debouncedSchoolName);
	const schools = data ? data?.map((s) => s.name) : [];
	const school = data?.find((s) => s.name === schoolName);

	useEffect(() => {
		setDebouncedSchoolName(schoolName);
	}, [schoolName, setDebouncedSchoolName]);

	useEffect(() => {
		if (school) {
			dispatch(updateSchool(school));
		} else {
			dispatch(
				updateSchool({
					id: 0,
					name: debouncedSchoolName,
					selfServe: true,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				})
			);
		}
	}, [dispatch, school, debouncedSchoolName]);

	const { field: schoolNameField } = useController<FieldValues>({
		name: 'schoolName'
	});

	return (
		<>
			<TextControl
				name="firstName"
				label="First Name"
				error={errors.firstName?.message}
				inputProps={{ ...register('firstName'), placeholder: 'First' }}
			/>

			<TextControl
				name="lastName"
				label="Last Name"
				error={errors.lastName?.message}
				inputProps={{ ...register('lastName'), placeholder: 'Last' }}
			/>

			<TextControl
				name="email"
				label="Email"
				error={errors.email?.message}
				inputProps={{
					...register('email'),
					placeholder: 'someone@somewhere.com',
					type: 'email',
					isReadOnly: true
				}}
			/>

			<TypeaheadControl
				name="schoolName"
				label="School Name"
				items={Array.from(new Set(schools))}
				school={school}
				field={schoolNameField}
				setValue={(value: string) => {
					setValue('schoolName', value);
				}}
			/>
		</>
	);
};

export default YourInformationForm;
