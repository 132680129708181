import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
	createCourseFormSliceReducer,
	contentSliceReducer,
	defaultsSliceReducer,
	chapterOrganizationSliceReducer
} from 'store/slices';
import { SelfServeApi, WebtextApi } from './api';

const reducers = combineReducers({
	createCourseFormSliceReducer,
	contentSliceReducer,
	defaultsSliceReducer,
	chapterOrganizationSliceReducer,
	[SelfServeApi.selfServeApi.reducerPath]: SelfServeApi.selfServeApi.reducer,
	[WebtextApi.webtextApi.reducerPath]: WebtextApi.webtextApi.reducer
});

const persistedReducer = persistReducer(
	{
		key: 'root',
		version: 1,
		storage,
		blacklist: [SelfServeApi.selfServeApi.reducerPath, WebtextApi.webtextApi.reducerPath]
	},
	reducers
);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
			.concat(SelfServeApi.selfServeApi.middleware)
			.concat(WebtextApi.webtextApi.middleware),
	devTools: true
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
