import { useRef } from 'react';
import { addWeeks, format, formatISO, parseISO } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { DraggableLocation } from 'react-beautiful-dnd';

import { DEFAULT_CHAPTER_VALUES } from 'store/api/constants';
import {
	Chapter,
	CreateCourseFormKey,
	CreateCourseFormSection,
	CreateCourseFormSectionStatus,
	IntegrationsState,
	FormDataStateElementKey,
	IntegrationsStateKeys,
	TermInformationStateKeys,
	YourInformationStateKeys,
	BasicCourseDetailsStateKeys,
	YourInformationState,
	BasicCourseDetailsState,
	TermInformationState,
	CourseReviewResponse,
	CreateCourseFormGlobalState,
	ChapterOrganizationState,
	TextbookChapter
} from 'types';

export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

export function move<T>(
	source: T[],
	destination: T[],
	droppableSource: DraggableLocation,
	droppableDestination: DraggableLocation
): {
	[x: string]: T[];
} {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	return {
		[droppableSource.droppableId]: sourceClone,
		[droppableDestination.droppableId]: destClone
	};
}

export function arrayToIndexedObjectArray<T>(items: T[]): { id: string; content: T }[] {
	return items.map((item, index) => ({
		id: `${index + 1}`,
		content: item
	}));
}

export function camelToTitleCase(camelCase: string): string {
	return camelCase
		.replace(/([A-Z])/g, function (match) {
			return ' ' + match;
		})
		.replace(/^./, function (match) {
			return match.toUpperCase();
		})
		.replace('L M S', 'LMS');
}

export function displayBoolean(input: string): string {
	if (input === 'true') return 'Yes';
	if (input === 'false') return 'No';
	return input;
}

export function getMinMax(characterLimit?: number): { min: number; max: number } {
	return { min: 0, max: Number('9'.repeat(characterLimit || 1)) };
}

export const range = (x: number, y: number): number[] =>
	Array.from(
		(function* () {
			while (x <= y) yield x++;
		})()
	);

export function intersect<T>(a: T[], b: T[]): T[] {
	const setB = new Set(b);
	return [...Array.from(new Set(a))].filter((x) => setB.has(x));
}

export const formatDate = (iso: string): string => {
	if (!iso) return '{PROBLEM}';

	return format(parseISO(iso), 'MM/dd/yyyy');
};

export const getYearFromDate = (iso: string): string => {
	if (!iso) return '{PROBLEM}';
	return String(parseISO(iso).getFullYear());
};

export function findSection(fieldKey: FormDataStateElementKey): CreateCourseFormKey | undefined {
	if (YourInformationStateKeys.includes(fieldKey as keyof YourInformationState)) {
		return 'yourInformation';
	}

	if (BasicCourseDetailsStateKeys.includes(fieldKey as keyof BasicCourseDetailsState)) {
		return 'basicCourseDetails';
	}

	if (TermInformationStateKeys.includes(fieldKey as keyof TermInformationState)) {
		return 'termInformation';
	}

	if (IntegrationsStateKeys.includes(fieldKey as keyof IntegrationsState)) {
		return 'integrations';
	}
}

export function checkSectionStatus<T>(
	section: CreateCourseFormSection & T,
	sectionKey?: CreateCourseFormKey
): CreateCourseFormSectionStatus {
	if (sectionKey === 'integrations') {
		const integrationsSection = section as unknown as IntegrationsState;

		if (integrationsSection.willThisCourseBeIntegratedWithAnLMS === 'No') {
			return 'complete';
		}
	}

	/*
	 * If value is false then it is set, things that should be evaluated as not complete would be
	 * empty strings, undefined, etc.
	 */
	const result = [
		...Array.from(
			new Set(
				Object.keys(section)
					.filter((k) => k !== 'status' && k !== 'privateTermName')
					.map((k) => {
						const r =
							(section[k as keyof T] as unknown as boolean) === false ||
							Boolean(section[k as keyof T]);
						return r;
					})
			)
		)
	];

	if (result.length === 1 && result.includes(true)) {
		return 'complete';
	}

	return 'incomplete';
}

export function useDebug(name: string): void {
	const renderCount = useRef(0);
	renderCount.current = renderCount.current + 1;
	console.log(`${name} rendered [${renderCount.current}]`);
}

export function transformCourseReviewResponse(
	response: CourseReviewResponse
): Partial<CreateCourseFormGlobalState> {
	return {
		firstName: response.user.firstName,
		lastName: response.user.lastName,
		email: response.user.email,
		courseName: response.course.name,
		courseSectionNumber: response.course.number,
		webtext: response.textbook.id,
		schoolName: response.school.name
	};
}

export function isComponentizedPreviewEnabledFor(schoolId: number) {
	if (!process.env.REACT_APP_ENABLE_COMPONENTIZED_PREVIEW_FOR) return false;

	return (process.env.REACT_APP_ENABLE_COMPONENTIZED_PREVIEW_FOR)
		.split(',')
		.includes(`${schoolId}`);
}

/**
 * Get browser timeZone to translate the `date` to utc with offset.
 * Then translate the offset date to the timeZone of the school.
 */
export function translateTimeZone(date: Date, timeZone: string): Date {
	const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const browserUtcDate = zonedTimeToUtc(date, browserTimeZone);
	return utcToZonedTime(browserUtcDate, timeZone);
}

/**
 * Translate dateString to timeZone.
 */
export function translateDateToTimeZone(dateString: string, timeZone: string): string {
	const baseDate = parseISO(dateString);
	return formatISO(translateTimeZone(baseDate, timeZone));
}

export function defaultDueDate(start?: string, end?: string): string | undefined {
	if (!start) return;

	if (end) {
		return end;
	} else {
		const dueDate = addWeeks(parseISO(start), 3);
		return formatISO(dueDate);
	}
}

export function prepareChapters(
	textbooksChapters: TextbookChapter[],
	timeZone: string,
	termStartDate?: string,
	termEndDate?: string
): ChapterOrganizationState {
	return {
		isLocked: false,
		removedChapters: [],
		chapters: textbooksChapters.map<Chapter>((tc) => ({
			chapterNumber: Number(tc.chapterNumber),
			chapterName: tc.chapterName,
			chapterFamilyId: tc.chapterFamilyId,
			points: DEFAULT_CHAPTER_VALUES.points,
			dueDate: translateDateToTimeZone(defaultDueDate(termStartDate, termEndDate) || '', timeZone),
			dueTime: '11:59 PM',
			penaltyPercent: DEFAULT_CHAPTER_VALUES.penaltyPercent,
			penaltyPeriod: DEFAULT_CHAPTER_VALUES.penaltyPeriod
		}))
	};
}
