import React from 'react';
import cn from 'classnames';
import { Box, BoxProps } from '@chakra-ui/react';
import { css } from '@emotion/react';

import { usePreviewParams } from 'hooks';
import { useNavbarContext } from './NavbarContext';
import { Page } from './page';

interface Props {
	none?: boolean;
}

const Content: React.FC<Props & BoxProps> = (props) => {
	const { pageFamilyId } = usePreviewParams();
	const { isAnyOpen, isLeftSideOpen, isRightSideOpen } = useNavbarContext();

	return (
		<Box css={styles} {...props} style={{ overflow: isAnyOpen ? 'hidden' : 'auto' }}>
			<Box
				className={cn('webtext-content', {
					inert: isAnyOpen,
					'open-left': isLeftSideOpen,
					'open-right': isRightSideOpen
				})}
				{...{ inert: isAnyOpen ? '' : undefined }}>
				{pageFamilyId && <Page />}
			</Box>
		</Box>
	);
};

const styles = () => css`
	overflow-y: scroll;

	.webtext-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		max-width: 700px;

		width: 90%;
		padding: 2rem 0;
		margin: 0 auto;
		position: relative;
		transition: left 0.5s ease;

		overflow-y: scroll;
	}

	.webtext-content.open-left {
		left: 266px;
	}

	.webtext-content.open-right {
		left: -266px;
	}

	h1 {
		font-size: 4em;
		margin-bottom: 0.5em;
	}

	p {
		font-size: 18px;
		margin-bottom: 1em;
	}

	.divider {
		height: 4px;
		background-color: #999;
		width: 100%;
		margin: 1rem 0;
	}
`;

export default Content;
