import React, { useEffect } from 'react';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';

import { useAppSelector } from 'store';
import { selectVerified } from 'store/slices/createCourseFormSlice';
import { Create } from 'components/pages';
import { ChangeWebtextModal } from 'components/modals';
import { useQuery, useTrackEvent } from 'hooks';

const PreviewNavigation: React.FC = () => {
	const queryParams = useQuery();
	const trackEvent = useTrackEvent();
	const verified = useAppSelector(selectVerified);
	const openCreate = queryParams.get('create') === 'true';
	const { isOpen: isCreateOpen, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();

	/**
	 * If `openCreate` is true we need to open the Create modal automatically
	 */
	useEffect(() => {
		if (openCreate) {
			/**
			 * Wait for query parameter to be committed to the store and propagate. The timeout here
			 * is to avoid a possible race condition.
			 */
			setTimeout(() => {
				/**
				 * Based on the `openCreate` variable, the Create modal may auto open.  So we will
				 * track when this happens.
				 */
				trackEvent('create-modal-started', { from: 'auto' });
				onOpenCreate();
			}, 1250);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			as="nav"
			background="brand.500"
			py="2px"
			px="3px"
			borderColor="brand.600"
			borderBottomWidth="1px"
			borderTopWidth="1px"
			w="100%"
			overflow="hidden">
			<Stack
				background="white"
				p={[2, 3]}
				py={[1, 3]}
				spacing={[3, 6]}
				borderRadius="md"
				boxShadow="lg"
				direction={['column', 'column', 'row']}
				justify="space-between"
				align="center">
				<Text fontWeight="500" flexBasis="100%" fontSize={['sm', 'md']}>
					{!verified
						? `You’re viewing a limited preview of this webtext. Once your instructor status has been verified, you’ll receive an email granting full access.`
						: `Enjoy full access to our webtexts! 💫 If you have any questions, contact us. `}
				</Text>
				<Stack direction={['row']} justify="center" alignItems="center">
					<ChangeWebtextModal />

					<Create
						isOpen={isCreateOpen}
						onOpen={() => {
							/**
							 * Track when the Create modal is opened manually by the user.
							 */
							trackEvent('create-modal-started', { from: 'blue-button' });
							onOpenCreate();
						}}
						onClose={onCloseCreate}>
						Create a course
					</Create>
				</Stack>
			</Stack>
		</Box>
	);
};

export default PreviewNavigation;
