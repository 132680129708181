import React, { FC, ReactElement, Fragment } from 'react';

import { Nesting, QuestionDeck, Spacer } from '@soomo/lib/components/pageElements';
import { MCQuestionElement, NestingInstruction, PageElementType, QuestionDeckElement, QuestionDeckElementType } from '@soomo/lib/types/WebtextManifest';

import { Element, Props as ElementProps } from './Element';
import type { CustomPageType } from '.';

interface Props {
	pageManifest: CustomPageType;
}

const PageElements: FC<Props> = (props) => {
	const { pageManifest } = props;
	const pageElements = Object.values(pageManifest.elements);

	// Parent "in" direction deck element
	let questionDeckDirectionIn: QuestionDeckElement | null = null;
	let questionDeckElements: Array<QuestionDeckElementType> = [];

	let nestingInstructionClasses: string[] | null = null;
	let nestingInstructionElements: ReactElement[] = []; // only supports one level of nesting

	return (
		<>
			{pageElements.map((element) => {
				let customElement: ReactElement | null = null;
				const elementProps: Partial<ElementProps> = {};

				if (nestingInstructionClasses) {
					elementProps.containerType = 'nesting';
				}

				switch (element.type as PageElementType) {
					case 'nesting_instruction': {
						const nestingInstruction = element as NestingInstruction;

						if (nestingInstruction.direction === 'in') {
							nestingInstructionClasses = nestingInstruction.container_classes || [];
							return null; // Skip rendering of the in-nesting element
						}

						customElement = (
							<Nesting
								familyId={nestingInstruction.family_id}
								nestingInstructionClasses={nestingInstructionClasses ?? []}
								nestingInstructionElements={nestingInstructionElements}
							/>
						);

						nestingInstructionClasses = null;
						nestingInstructionElements = [];
						break;
					}
					case 'question_deck': {
						const questionDeck = element as QuestionDeckElement;

						if (questionDeck.direction === 'in') {
							questionDeckDirectionIn = questionDeck;
							return null;
						}

						switch (questionDeckElements[0]?.type) {
							case 'mc_question': {
								/*
								const nextQuestionReminderEnabled =
									toc.config.pages.types[page.page_type].elements['QuestionDeck']?.settings
										?.next_question_reminder_enabled ?? false;
								*/

								customElement = (
									<QuestionDeck
										key={questionDeck.family_id}
										questions={[...questionDeckElements] as MCQuestionElement[]}
										nextQuestionReminderEnabled={false}
										answers={{}}
										onChangeSelectedChoice={() => {
											console.log('default');
										}}
										onSubmit={() => {
											console.log('default');
										}}
									/>
								);
								break;
							}
							case 'mc_question_pool':
								/*
								customElement = (
									<MCQuestionPoolQuestionDeck
										key={questionDeckDirectionIn!.family_id}
										familyId={questionDeckDirectionIn!.family_id}
										questionPoolElements={questionDeckElements as LibsMCQuestionPoolElement[]}
									/>
								);
								*/
								break;
							case 'poll_question':
							case 'poll_results':
								/*
								customElement = (
									<PollsDeck
										key={questionDeckDirectionIn!.family_id}
										familyId={questionDeckDirectionIn!.family_id}
										pollResultDisplayStyle={questionDeckDirectionIn!.pollResultDisplayStyle}
										pollElementsProps={
											questionDeckElements as Array<
												| PollQuestionElement
												| PollResultsAliasElement
											>
										}
									/>
								);
								*/
								break;
							default:
								break;
						}

						questionDeckDirectionIn = null;
						questionDeckElements = [];
						break;
					}
					case 'mc_question':
					case 'mc_question_pool':
					case 'poll_question': {
						if (questionDeckDirectionIn) {
							questionDeckElements.push(element as QuestionDeckElementType);
							return null;
						}
						break;
					}
				}

				const defaultElement = <Element element={element} page={pageManifest} {...elementProps} />;

				if (nestingInstructionClasses) {
					nestingInstructionElements.push(defaultElement);
					return null;
				}

				return (
					<Fragment key={element.family_id}>
						{customElement ?? defaultElement}

						<Spacer />
					</Fragment>
				);
			})}
		</>
	);
};

export default PageElements;
