import React, { FC, useMemo } from 'react';

import {
	ArtifactElement,
	BasePageElement,
	ChartElement,
	ExternalToolElement,
	FamilyId,
	FigureElement,
	GeoMapElement,
	GoReactElement,
	MCQuestionElement,
	MCQuestionPoolElement,
	Page,
	PageElementType,
	Policy,
	PollQuestionElement,
	PopupQuizElement,
	ResponseBoardElement,
	SAQuestionElement,
	TimelineElement,
	WritingTemplateElement,
	TextElement as TextElementType
} from '@soomo/lib/types/WebtextManifest';
import { GeoMapType } from '@soomo/lib/types';
import {
	ArtifactLink,
	ChartFigure,
	Figure,
	GeoMapFigure,
	GoReact,
	MultipleChoiceQuestion,
	InstructorPollQuestion,
	ResponseBoardQuestion,
	ShortAnswerQuestion,
	StandaloneMCQuestionPool,
	Timeline,
	WritingTemplate
} from '@soomo/lib/components/pageElements';
import { default as PopupQuizContainer } from '@soomo/lib/components/pageElements/PopupQuiz/PopupQuizContainer';
import themes from '@soomo/lib/styles/themes';

import { ElementPlaceholder } from './ElementPlaceholder';
import { TextElement } from './TextElement';
import { useNavbarContext } from '../NavbarContext';
import {
	PopupQuizAPI,
	PopupQuizAPIResetResponse,
	PopupQuizAPISaveResponse,
	PopupQuizResults
} from '@soomo/lib/components/pageElements/PopupQuiz/types';

export interface Props {
	element: BasePageElement;
	containerType?: string;
	page: Page;
}

export const Element: FC<Props> = (props) => {
	const { element, page, containerType } = props;
	const { themeName } = useNavbarContext();

	const puqApi = useMemo<PopupQuizAPI>(
		() => ({
			save: (
				familyId: FamilyId,
				responses: { [questionFamilyId: string]: FamilyId },
				post: boolean
			) => {
				console.log('save', familyId, responses, post);

				return Promise.resolve<PopupQuizAPISaveResponse>({
					data: {
						last_saved_at: new Date().toISOString(),
						resultsAvailable: false,
						results: { completed: false } as PopupQuizResults,
						resetCount: 0
					}
				});
			},
			reset: (familyId: FamilyId) => {
				console.log('reset', familyId);

				return Promise.resolve<PopupQuizAPIResetResponse>({
					data: {
						resetCount: 0
					}
				});
			}
		}),
		[]
	);

	switch (element.type as PageElementType) {
		case 'text':
			return (
				<TextElement
					element={element as TextElementType}
					citationReferences={page.citation_references}
					containerType={containerType}
				/>
			);

		case 'figure':
			return (
				<Figure
					figureOptions={element as FigureElement}
					elementContext={{ pageFamilyId: page.family_id, courseId: 0 }}
				/>
			);

		case 'mc_question': {
			const mcQuestion = element as MCQuestionElement;
			return (
				<MultipleChoiceQuestion
					questionFamilyId={mcQuestion.family_id}
					body={mcQuestion.body}
					choices={mcQuestion.choices}
					onSubmit={() => console.log(`submit: ${mcQuestion.family_id}`)}
				/>
			);
		}

		case 'poll_question': {
			// TODO: temporary using Omit until types are fixed as there are no questionFamilyId
			const pollQuestion = element as Omit<PollQuestionElement, 'questionFamilyId'>;
			const pollAnswer = {
				graphData: pollQuestion.choices.map((choice) => ({ label: choice.body, data: 0 })),
				updated_at: ''
			};

			const courseNumber = 'TEST-1-2-3'; // TODO: course.course_number
			const totalNumberOfStudents = 10; // TODO: course.enrollments.students.count
			const tabsUi = true; // TODO: toc.config.pages.types[pageType].elements['Poll']?.settings?.tabs_ui

			return (
				<InstructorPollQuestion
					{...pollQuestion}
					online
					courseNumber={courseNumber}
					questionFamilyId={pollQuestion.family_id}
					answer={pollAnswer}
					tabsUi={tabsUi}
					totalNumberOfStudents={totalNumberOfStudents}
				/>
			);
		}

		case 'poll_results': {
			// const targetPollQuestionFamilyId = (element as PollResultsAliasElement)
			// 	.pollQuestionFamilyId;
			const targetPollQuestion = {} as Omit<PollQuestionElement, 'questionFamilyId'>; //TODO: elements[targetPollQuestionFamilyId]
			const targetPollAnswer = {
				graphData: targetPollQuestion.choices?.map((choice) => ({
					label: choice.body,
					data: 0
				})),
				updated_at: ''
			};

			const courseNumber = 'TEST-1-2-3'; // TODO: course.course_number
			const totalNumberOfStudents = 10; // TODO: course.enrollments.students.count
			const tabsUi = true; // TODO: toc.config.pages.types[pageType].elements['Poll']?.settings?.tabs_ui

			return (
				<InstructorPollQuestion
					{...targetPollQuestion}
					online
					courseNumber={courseNumber}
					questionFamilyId={targetPollQuestion.family_id}
					answer={targetPollAnswer}
					tabsUi={tabsUi}
					totalNumberOfStudents={totalNumberOfStudents}
				/>
			);
		}

		case 'sa_question': {
			const saQuestion = element as SAQuestionElement;
			return (
				<ShortAnswerQuestion
					userId={'self-serve'}
					questionId={saQuestion.family_id}
					body={saQuestion.body}
					answer={{}}
					referenceAnswer={saQuestion.reference_answer || ''}
					validationMessage={''}
					online
				/>
			);
		}

		case 'response_board': {
			const rbQuestion = element as ResponseBoardElement;

			return (
				<ResponseBoardQuestion
					userId={'self-serve'}
					questionId={rbQuestion.family_id}
					body={rbQuestion.body}
					userPost={{
						body: '',
						postedAt: '',
						isPinned: false,
						pinnedBy: '',
						author: { name: 'self-serve' }
					}}
					coursePosts={[]}
					sharingAcknowledged={true}
					showResponses={false}
					setShowResponses={() => {
						console.log('default');
					}}
					validationMessage=""
					editable={false}
					online
				/>
			);
		}

		case 'interactive_template': {
			const writingTemplate = element as WritingTemplateElement;
			return (
				<WritingTemplate
					builderId={writingTemplate.family_id}
					schema={writingTemplate.config}
					toc={{}}
				/>
			);
		}

		case 'chart': {
			const chart = element as ChartElement;
			const tabsUi = false; // toc.config.pages.types[pageType].elements['Chart']?.settings?.tabs_ui;
			return <ChartFigure chart={{ ...chart, tabsUi }} monochromeCharts={false} />;
		}

		case 'geo_map': {
			const geoMap = element as GeoMapElement;

			const topologyJsonPathId = {
				[GeoMapType.US]: 'USA',
				[GeoMapType.World]: 'WORLD'
			};

			return (
				<GeoMapFigure
					figure={{
						...geoMap,
						version: element.version
					}}
					getTopology={(type) =>
						fetch(`/assets/topojson_${topologyJsonPathId[type]}.min.json`).then((res) => res.json())
					}
				/>
			);
		}

		case 'mc_question_pool': {
			const mcQuestionPool = element as MCQuestionPoolElement;

			return (
				<StandaloneMCQuestionPool
					courseId={0}
					chapterFamilyId="dds"
					questionPool={mcQuestionPool}
					showInstructorView={false}
					userId="self-serve"
					attemptsAllowed={3}
					onSave={() => {
						return Promise.resolve({
							answer: {
								id: 0,
								body: '',
								data: {},
								completed: false,
								correct: false,
								created_at: Date.toString(),
								updated_at: Date.toString(),
								first_saved_at: Date.toString(),
								question_family_id: mcQuestionPool.family_id,
								quiz_response_id: 0,
								rejoinder: ''
							}
						});
					}}
					onReset={() => {
						console.log('default');
					}}
					answer={{
						id: 0,
						body: '',
						data: {},
						completed: false,
						correct: false,
						created_at: Date.toString(),
						updated_at: Date.toString(),
						first_saved_at: Date.toString(),
						question_family_id: mcQuestionPool.family_id,
						quiz_response_id: 0,
						rejoinder: ''
					}}
					resetCount={0}
				/>
			);
		}

		case 'artifact_link': {
			const artifactElement = element as ArtifactElement;
			const artifactType = artifactElement.ruby_class.split('::').slice(-1)[0].toLowerCase();

			/**
			 * @todo
			 * need handle the on event props
			 */
			return (
				<ArtifactLink
					type={artifactType}
					href={artifactElement.artifact_url}
					transcriptHref={artifactElement.transcript_url}
					credits={artifactElement.credits}>
					{artifactElement.title}
				</ArtifactLink>
			);
		}

		case 'timeline': {
			const timelineElement = element as TimelineElement;

			return <Timeline element={timelineElement} />;
		}

		case 'self_assessment': {
			const puqElement = element as PopupQuizElement;

			return (
				<PopupQuizContainer
					popupQuiz={puqElement.config}
					popupQuizLoadResponse={undefined}
					policy={
						{
							policy_name: 'test policy',
							allow_reset: true,
							attempts_allowed: 0,
							graded: true,
							initial_response_warning: 'RESET',
							mc_question_type_label: 'Multiple Choice',
							reset_acknowledgement: false,
							reset_confirmation: 'Confirm reset',
							reset_snoozed_complete: 'You can reset this quiz now COMPLETE',
							reset_snoozed_incomplete: 'You can reset this quiz now INCOMPLETE',
							time_between_resets: 0
						} as Policy
					}
					api={puqApi}
					ally={{
						disableFocusTrapping: true,
						maintainAvailableFilter: '[data-will-never-match-anything]'
					}}
					pollingOptions={{
						disabled: true,
						scrollListenerSelector: '[data-will-never-match-anything]'
					}}
					theme={themes[themeName]}
					readOnly={false}
					online
				/>
			);
		}

		case 'external_tool': {
			const externalTool = element as ExternalToolElement;

			if (externalTool.tool_implementation === 'ExternalTools::GoReactTool') {
				const goReactElement = element as GoReactElement;

				return (
					<GoReact
						externalTool={goReactElement}
						toolConfig={{
							humanName: 'Video Recorder',
							launchURL: 'GET FROM MANIFEST?',
							launchParams: { key: 'GET FROM MANIFEST?' },
							toolHeight: 'GET FROM MANIFEST?'
						}}
					/>
				);
			}

			return <ElementPlaceholder elementType={element.type} />;
		}

		default:
			return <ElementPlaceholder elementType={element.type} />;
	}
};
