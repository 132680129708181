import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { FaAngleRight } from 'react-icons/fa';

import { numberToOrdinalWord } from '@soomo/lib/utils/formatting';

import { useGetManifestQuery } from 'store/api';
import { useNavbarContext } from './NavbarContext';
import { getNavbarStyle, NavbarStyle } from './theme';
import { usePreviewParams } from 'hooks';

const Cover = () => {
	const { setPageFamilyId } = usePreviewParams();
	const { previewCourseId, spaceToNegate, themeName } = useNavbarContext();

	const { data } = useGetManifestQuery({
		courseId: previewCourseId,
		short: true
	});

	const firstPage = useMemo(() => {
		if (!data) return;

		const firstChapter = data.chapters[Object.keys(data.chapters)[0]];
		const firstPageId = firstChapter.page_ids[0];

		return firstPageId;
	}, [data]);

	const tocSettings = data?.toc.config.settings;
	const tocBook = data?.toc.config.book;

	const coverImgUrl = tocSettings?.webtext_cover_background_image_url || '';
	const titleHtml = tocSettings?.webtext_cover_title_html_content || '';
	const edition = numberToOrdinalWord(tocBook?.edition_number || 0);
	const author = tocBook?.author;
	const copyrightNotice = tocBook?.copyright_notice;

	return (
		<Box css={styles(getNavbarStyle(themeName), coverImgUrl, spaceToNegate)}>
			<div className="course-cover-image-container">
				<div className="course-cover-image" />
			</div>
			<div className="course-cover-content">
				<div className="cover-upper">
					<h1 dangerouslySetInnerHTML={{ __html: titleHtml }} />
					{edition ? <div className="edition">{edition} edition</div> : null}
				</div>
				<div className="cover-lower">
					{author && <span className="author">{author}</span>}
					{author && <div className="divider"></div>}
					{copyrightNotice && <span className="copyright">{copyrightNotice}</span>}
				</div>

				<a
					className="enter-course"
					onClick={() => {
						if (firstPage) {
							setPageFamilyId(firstPage);
						}
					}}>
					Enter <FaAngleRight fontSize="1.3em" />
				</a>
			</div>
		</Box>
	);
};

const styles = (navbarStyle: NavbarStyle, imgUrl: string, spaceToNegate: number) => css`
	z-index: 1000;
	height: 100%;

	.course-cover-image-container {
		position: absolute;
		left: 0;
		top: ${spaceToNegate}px;
		width: 100%;
		height: calc(100% - ${spaceToNegate}px);
		display: flex;
		flex-direction: column;
	}

	.course-cover-image {
		width: 100%;
		height: 100%;
		background-image: url(${imgUrl});
		background-origin: padding-box;
		background-size: cover;
		//background-position-x: 0%;
		//background-position-y: 100%;
		background-position: center center;
		background-repeat: no-repeat;
	}

	${navbarStyle.isUV &&
	css`
		.course-cover-image::before {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			bottom: 0;
			background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.18));
		}
	`}

	.course-cover-content {
		position: relative;
		z-index: 2;

		height: 100%;
		width: 90%;
		max-width: 1000px;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex-grow: 1;

		margin-left: auto;
		margin-right: auto;

		color: #fefefe;

		h1 {
			margin-top: 90px;
			color: #fefefe;
			font-size: 80px;
			line-height: 64px;
		}

		.edition {
			margin: 20px 0 15px;
			font-size: 18px;
			font-weight: 500;
		}

		.author {
			font-size: 18px;
			font-weight: bold;
		}

		.copyright {
			font-size: 11px;
			margin-bottom: 24px;
		}

		.cover-upper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}

		.cover-lower {
			display: flex;
			flex-direction: column;
		}

		.cover-lower .divider {
			display: none;
		}

		${!navbarStyle.isUV &&
		css`
			text-transform: uppercase;

			h1 {
				font-family: 'Proxima N W15 Bold', sans-serif;
			}

			.edition:after {
				content: '';
				width: 60px;
				height: 0;
				border-bottom: 5px solid #fefefe;
				display: block;
				margin-top: 15px;
			}
		`}

		${navbarStyle.isUV &&
		css`
			align-items: center;
			justify-content: center;

			h1 {
				margin-top: 0;
				font-size: 104px;
				font-weight: 800;
				line-height: 105px;

				padding-left: 20px;
				padding-right: 20px;
				text-align: center;
			}

			.edition {
				font-weight: 500;
				letter-spacing: 7.23px;
				text-transform: uppercase;
			}

			.cover-upper {
				flex-direction: column-reverse;
				align-items: center;
				gap: 30px;
				margin-bottom: 40px;
			}

			.cover-lower {
				flex-direction: row;
				align-items: center;
				margin-bottom: 125px;
			}

			.cover-lower :is(.author, .copyright) {
				font-size: 16px;
				font-weight: normal;
				text-transform: none;
				margin-bottom: 0;
			}

			.cover-lower .divider {
				display: block;
				content: '';
				border-left: 1px solid white;
				margin-left: 1em;
				margin-right: 1em;
				width: 1;
				height: 48px;
			}
		`}
	}

	.enter-course {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		gap: 3px;

		border: 1px solid #fefefe;
		border-radius: 2px;
		padding: 0.6em 1.2em;
		color: #fefefe;
		font-size: 19px;
		text-decoration: none;
		text-transform: uppercase;
		position: relative;

		${navbarStyle.isUV &&
		css`
			background: #ffffff;
			box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.21);
			border-radius: 6px;
			text-transform: capitalize;
			color: #5f01df;
			width: 170px;
			text-align: center;
			height: 50px;
			font-size: 18px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			svg {
				display: none;
			}
		`}
	}

	@media (max-width: 650px) {
		.course-cover-content {
			.cover-upper {
				h1 {
					font-size: 32px;
					line-height: 0.75;
				}
			}

			.cover-lower {
				gap: 5px;

				*:is(.author, .copyright) {
					font-size: 10px !important;
					line-height: 0.75;
				}
			}
		}

		.enter-course {
			padding: 0.3em 0.6em;
		}

		${navbarStyle.isUV &&
		css`
			.course-cover-content {
				.cover-upper {
					gap: 14px;
					margin-bottom: 18px;

					.edition {
						font-size: 12px;
						letter-spacing: 3.03px;
						margin: 0;
					}

					h1 {
						font-size: 39px;
						line-height: 37px;
						margin-left: 23px;
						margin-right: 23px;
					}
				}

				.cover-lower {
					flex-direction: column;
					margin-bottom: 2em;

					*:is(.author, .copyright) {
						font-size: 13px !important;
						line-height: 20px;
					}

					.divider {
						display: none;
					}
				}
			}
		`}
	}
`;

export default Cover;
