import { useLocation } from 'react-router-dom';

export { useTrackEvent, useBootIntercom, useTrackExternalWebtext } from './intercom';
export { useSyncWithServer } from './useSyncWithServer';
export { useInterval } from './useInterval';
export { useSelfServeConfig } from './useSelfServeConfig';
export { usePreviewParams } from './usePreviewParams';

export function useQuery(): URLSearchParams {
	return new URLSearchParams(useLocation().search);
}
