import React, { useMemo } from 'react';
import cn from 'classnames';
import { css } from '@emotion/react';
import { getNavbarStyle, NavbarStyle } from './theme';
import { DrawerSide, useNavbarContext } from './NavbarContext';
import { Box } from '@chakra-ui/react';
import { FaAngleDown, FaAngleUp, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { ThemeName } from '@soomo/lib/styles/themes';

type DropdownLink = {
	title: string;
	href: string;
};

interface Props {
	handle: React.ReactNode;
	side: DrawerSide;
	items?: DropdownLink[];
	open: boolean;
	setOpen: (open: boolean) => void;
	hideToggle?: boolean;
}

const NavMenu: React.FC<Props> = (props) => {
	const { handle, side, items, open, setOpen, hideToggle, children } = props;

	const { themeName } = useNavbarContext();

	const buttonIcon = useMemo(() => {
		if (open) {
			return themeName === ThemeName.UNIVERSAL_VELVET ? <FaChevronUp /> : <FaAngleUp />;
		} else {
			return themeName === ThemeName.UNIVERSAL_VELVET ? <FaChevronDown /> : <FaAngleDown />;
		}
	}, [open, themeName]);

	return (
		<Box css={styles(getNavbarStyle(themeName), side, open)}>
			<button
				className="drawer-nav-list-item-btn"
				onClick={() => setOpen(!open)}
				aria-expanded={open}
				aria-label={open ? 'Hide' : 'Show'}>
				{handle}
			</button>

			{!hideToggle && (
				<button
					className="drawer-sub-nav-toggle"
					onClick={() => setOpen(!open)}
					tabIndex={-1}
					aria-expanded={open}
					aria-label={open ? 'Hide' : 'Show'}>
					{buttonIcon}
				</button>
			)}

			<Box as="ul" className={cn('drawer-sub-nav-list', { open: open })}>
				{children}

				{items &&
					items.map((item) => (
						<li key={item.title} className="drawer-sub-nav-list-item">
							<a href={item.href} target="_blank" rel="noreferrer">
								{item.title}
							</a>
						</li>
					))}
			</Box>
		</Box>
	);
};

const styles = (navbarStyle: NavbarStyle, side: DrawerSide, open: boolean) => css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	${navbarStyle.isUV &&
	open &&
	css`
		background: ${navbarStyle.drawerStyle.subMenu.background};
		margin-left: -36px;
		margin-right: -36px;
		padding-left: 36px;
		padding-right: 36px;
		padding-bottom: 1em;

		.drawer-nav-list-item-btn svg {
			color: #5f01df;
		}
	`}

	.drawer-sub-nav-list {
		flex: 1;
		display: none;
		visibility: hidden;

		padding: 3px 15px;
		background: ${navbarStyle.drawerStyle.subMenu.background};
		margin-left: ${navbarStyle.isUV ? '0' : '-14px'};
		min-width: ${navbarStyle.drawerStyle[side].width};
		font-size: ${navbarStyle.drawerStyle.subMenu.fontSize};
		font-weight: ${navbarStyle.drawerStyle.subMenu.fontWeight};
	}

	.drawer-sub-nav-list.open {
		display: block;
		visibility: visible;
	}

	.drawer-sub-nav-toggle svg {
		color: rgb(159, 159, 159);
		font-size: 20px;
	}

	.drawer-sub-nav-toggle:hover {
		color: ${navbarStyle.drawerStyle.subMenu.hover.color};
	}

	.drawer-sub-nav-list-item {
		padding: 6px 0;
		${!navbarStyle.isUV
			? css`
					font-size: 13px;
			  `
			: css`
					padding-left: 1em;
			  `};
	}

	.drawer-sub-nav-list-item:hover {
		color: ${navbarStyle.drawerStyle.subMenu.hover.color};
	}
`;

export default NavMenu;
