import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

import { useGetPageQuery } from 'store/api';

import PageElements from './PageElements';
import { useNavbarContext } from '../NavbarContext';
import type { CustomPageType } from '.';
import { usePreviewParams } from 'hooks';


const Page = () => {
	const { pageFamilyId } = usePreviewParams();
	const { previewCourseId } = useNavbarContext();
	const { data, isLoading } = useGetPageQuery({
		courseId: previewCourseId,
		pageFamilyId: pageFamilyId
	});

	if (isLoading) {
		return (
			<Flex w="100%" h="100%" justifyContent="center" alignItems="center">
				<Spinner size="xl" />
			</Flex>
		);
	}

	return (
		<div className="webtext-page">
			{data && <PageElements pageManifest={data as CustomPageType} />}
		</div>
	);
};

export default Page;
