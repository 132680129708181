import React, { useState, useEffect } from 'react';
import { Accordion, VStack, Text, Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { intersect, transformCourseReviewResponse } from 'utils';
import { useAppSelector, useAppDispatch } from 'store';
import { resetGlobalState, selectSchool, updateSchool } from 'store/slices/createCourseFormSlice';
import {
	BasicCourseDetailsForm,
	TermInformationForm,
	YourInformationForm,
	ChapterOrganizationForm,
	IntegrationsForm
} from 'components/forms/accordion';
import { SetupAccordionItem } from 'components/accordion';
import {
	CreateCourseFormGlobalState,
	YourInformationStateKeys,
	FormDataStateElementKey,
	CreateCourseFormSectionStatus,
	BasicCourseDetailsStateKeys,
	TermInformationStateKeys,
	IntegrationsStateKeys
} from 'types';
import { Card, ConfirmationModal } from 'components';
import { selectCourseId } from 'store/slices/contentSlice';
import { TextareaControl } from 'components/forms';
import { useInterval, useSelfServeConfig, useSyncWithServer } from 'hooks';
import {
	selectChapterOrganization,
	updateChapterOrganizationState
} from 'store/slices/chapterOrganizationSlice';

const SetupAccordion: React.FC = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { isCustomizable } = useSelfServeConfig();
	const chapterOrganizationState = useAppSelector(selectChapterOrganization);

	const courseId = useAppSelector(selectCourseId);
	const school = useAppSelector(selectSchool);
	const [currentItemIndex, setCurrentItemIndex] = useState(0);

	const {
		watch,
		formState: { errors },
		getValues,
		reset,
		register,
		trigger
	} = useFormContext<CreateCourseFormGlobalState>();

	const watchCourseName = watch('courseName');
	const watchCourseSectionNumber = watch('courseSectionNumber');

	const { syncWithServer, courseReviewState, isSuccessCourseReviewState, isErrorQuery } =
		useSyncWithServer({
			courseId,
			school,
			chapterOrganizationState
		});

	/**
	 * Handle response from `/course_review`
	 */
	useEffect(() => {
		if (isSuccessCourseReviewState) {
			/**
			 * Check to see if school id matches server.  This is helpful if the user specified a
			 * school outside of our system.  We can update the school id here.
			 */
			if (school.id !== courseReviewState?.school.id) {
				dispatch(updateSchool({ ...school, ...courseReviewState?.school }));
			}

			/**
			 * When auto-saving we want to use the current form state as the source of truth;
			 * however, before auto-saving starts we should use the initial `courseReviewState` as
			 * the source of truth because the form would not have been initialized yet.
			 */
			const nextState = {
				...getValues(),
				...courseReviewState?.course.selfServeOptions.global,
				...(courseReviewState && { ...transformCourseReviewResponse(courseReviewState) })
			};

			/**
			 * Reset the form state to the combined state
			 */
			reset(nextState);

			/**
			 * If the server has self-serve state for this course update redux's copy
			 */
			if (courseReviewState?.course.selfServeOptions.global) {
				dispatch(resetGlobalState(nextState));
			}

			/**
			 * Only hydrate `chapterOrganizationState` when it exists remotely, meaning we have done
			 * an initial sync with chapters prior to hydrating local state.
			 */
			if (
				courseReviewState?.course.selfServeOptions.chapterOrganization &&
				courseReviewState?.textbook.id === Number(getValues().webtext)
			) {
				dispatch(
					updateChapterOrganizationState(
						courseReviewState?.course.selfServeOptions.chapterOrganization
					)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseReviewState, isSuccessCourseReviewState, school, dispatch, getValues]);

	useEffect(() => {
		if (isErrorQuery) {
			history.push(`/error`);
		}
	}, [history, isErrorQuery]);

	/**
	 * Auto-save on a 5 second interval, there is logic inside of the `useSyncWithServer` hook to
	 * check for differences before sending the sync request out.
	 */
	useInterval(() => {
		syncWithServer();
	}, 5000);

	const isSectionValid = (
		sectionKeys: FormDataStateElementKey[]
	): CreateCourseFormSectionStatus => {
		/**
		 * This is a manuel check for optimization.  We know these values have not been collected
		 * yet.  If we check in this way we don't need to run validation over the entire form on
		 * first mount.
		 */
		if (sectionKeys.includes('estimatedNumberOfStudents')) {
			const { estimatedNumberOfStudents } = getValues();
			if (!estimatedNumberOfStudents) {
				return 'incomplete';
			}
		} else if (sectionKeys.includes('studentAccessDate')) {
			const { studentAccessDate } = getValues();
			if (!studentAccessDate) {
				return 'incomplete';
			}
		} else if (sectionKeys.includes('willThisCourseBeIntegratedWithAnLMS')) {
			const {
				willThisCourseBeIntegratedWithAnLMS,
				whichLMS,
				willYouAssignDueDates,
				willYouAssignAPenaltyPeriod
			} = getValues();
			if (!willThisCourseBeIntegratedWithAnLMS) {
				return 'incomplete';
			} else if (willThisCourseBeIntegratedWithAnLMS === 'Yes' && !whichLMS) {
				return 'incomplete';
			} else if (
				willThisCourseBeIntegratedWithAnLMS === 'Yes' &&
				(!willYouAssignDueDates || !willYouAssignAPenaltyPeriod)
			) {
				return 'incomplete';
			}
		}

		if (intersect(sectionKeys, Object.keys(errors)).length > 0) {
			return 'incomplete';
		}
		return 'complete';
	};

	const integrationsStatus = () => {
		if (chapterOrganizationState.isLocked || !isCustomizable) {
			return isSectionValid(IntegrationsStateKeys);
		}
		return 'locked';
	};

	const renderConfirmationModal = () => (
		<ConfirmationModal
			trigger={trigger}
			submitForm={() => syncWithServer()}
			disabled={
				Object.keys(errors).length > 0 ||
				(!chapterOrganizationState.isLocked && isCustomizable) ||
				integrationsStatus() !== 'complete' ||
				isSectionValid(BasicCourseDetailsStateKeys) !== 'complete' ||
				isSectionValid(TermInformationStateKeys) !== 'complete' ||
				!school?.selfServe
			}
			errors={[
				...Object.keys(errors),
				...(!school?.selfServe
					? [`Contact sales to create a new course for ${getValues().schoolName}`]
					: [])
			]}
			state={getValues()}>
			Review Course Summary
		</ConfirmationModal>
	);

	return (
		<Card mt="4" w="100%" variant="roundedNoPadding">
			<Stack
				direction={['column', 'row']}
				align={['flex-start', 'center']}
				w="100%"
				p="8"
				spacing="6"
				justify="space-between">
				<VStack align="left">
					<Text fontSize="2xl" fontWeight="bold">
						{watchCourseName}
					</Text>
					<Text fontSize="lg">{watchCourseSectionNumber}</Text>
				</VStack>
				{renderConfirmationModal()}
			</Stack>

			<Accordion
				allowToggle
				index={currentItemIndex}
				onChange={(index) => {
					// currentItemIndex here is the previously open item
					setCurrentItemIndex(typeof index === 'number' ? index : index[0]);
				}}
				borderColor="border.100"
				w="100%">
				<SetupAccordionItem
					title="Your Information"
					status={isSectionValid(YourInformationStateKeys)}>
					<YourInformationForm />
				</SetupAccordionItem>

				<SetupAccordionItem
					title="Basic Course Details"
					helpText="How many students will you have in this section?"
					status={isSectionValid(BasicCourseDetailsStateKeys)}>
					<BasicCourseDetailsForm />
				</SetupAccordionItem>

				<SetupAccordionItem
					title="Term Information"
					helpText="When do your students need access?"
					status={isSectionValid(TermInformationStateKeys)}>
					<TermInformationForm />
				</SetupAccordionItem>

				{isCustomizable && (
					<SetupAccordionItem
						title="Chapter Organization"
						helpText="Pick your chapter order."
						status={chapterOrganizationState.isLocked ? 'complete' : 'incomplete'}>
						<ChapterOrganizationForm />
					</SetupAccordionItem>
				)}

				<SetupAccordionItem
					title="Integrations &amp; Due Dates"
					helpText={
						integrationsStatus() === 'locked'
							? 'Save your chapter order above to unlock.'
							: "Tell us if you're integrating with an LMS."
					}
					status={integrationsStatus()}>
					<IntegrationsForm
						isOpen={isCustomizable ? currentItemIndex === 4 : currentItemIndex === 3}
					/>
				</SetupAccordionItem>
			</Accordion>

			<VStack w="100%" marginBottom="6" p="8" align="flex-end">
				<TextareaControl
					name="note"
					label="Additional Information for Soomo course specialists"
					textareaProps={{ ...register('note') }}
					helperText="Optional"
					onBlur={() => {
						if (!(getValues().note === courseReviewState?.course.selfServeOptions.global.note)) {
							syncWithServer();
						}
					}}
				/>

				{renderConfirmationModal()}
			</VStack>
		</Card>
	);
};

export default SetupAccordion;
