import React, { FC } from 'react';
import { FaCogs } from 'react-icons/fa';

import { Stack } from '@chakra-ui/react';
import { css } from '@emotion/react';

import { BasePageElement } from '@soomo/lib/types/WebtextManifest';

interface Props {
	elementType: BasePageElement['type'];
}

export const ElementPlaceholder: FC<Props> = (props) => {
	const { elementType } = props;
	return (
		<Stack className="not-annotatable" css={elementPlaceholderStyles.self}>
			<Stack alignItems="center">
				<h3>{elementType}</h3>
				<p>placeholder</p>
			</Stack>
			<FaCogs size="55px" />
		</Stack>
	);
};

const elementPlaceholderStyles = {
	self: () => css`
		align-items: center;
		gap: 15px;
		padding-top: 1em;
		padding-bottom: 1em;
		margin-bottom: 30px;

		color: black;
		border: 3px solid red;

		p {
			margin-bottom: 0;
		}
	`
};
