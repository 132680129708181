import { useHistory, useParams } from 'react-router-dom';

type PreviewPageParams = { textbookId: string; pageFamilyId: string };

export function usePreviewParams() {
	const history = useHistory();
	const { textbookId, pageFamilyId } = useParams<PreviewPageParams>();

	const setPageFamilyId = (pageFamilyId: string) => {
		history.push('/preview/' + textbookId + (pageFamilyId ? '/' + pageFamilyId : ''));
	};

	return { textbookId, pageFamilyId, isCoverPage: !pageFamilyId, setPageFamilyId };
}
