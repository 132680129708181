import { useEffect, useRef, useState } from 'react';

import { ThemeName } from '@soomo/lib/styles/themes';

import { useWindowSize } from 'utils/hooks';

export function useSpaceToNegate(themeName: ThemeName): number {
    const windowSize = useWindowSize();
	const containerRef = useRef(document.getElementById('self-serve-header'));
    const [spaceToNegate, setSpaceToNegate] = useState(0);

	/**
	 * Recalculate iframe size so it fills remaining screen space
	 *
	 * Need to do this when theme changes
	 */
	useEffect(() => {
		containerRef.current = document.getElementById('nav-bar-content');
		if (containerRef.current) {
			setSpaceToNegate(containerRef.current.getBoundingClientRect().height);
		}
	}, [containerRef, windowSize, themeName]);

    return spaceToNegate;
}
