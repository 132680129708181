import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Flex, Spinner, VStack } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from 'store';
import { Webtext } from 'components/webtext';
import { useGetTextbookReviewUrlQuery, useGetTextbooksQuery, useGetUserInfoQuery } from 'store/api';
import {
	updateGlobalState,
	selectVerified,
	updateVerified,
	selectSchool
} from 'store/slices/createCourseFormSlice';
import { useTrackExternalWebtext } from 'hooks';
import { InstructorApprovedModal } from 'components/modals';
import NavbarContextProvider from 'components/webtext/NavbarContext';
import { ThemeName } from '@soomo/lib/styles/themes';
import { useWindowSize } from 'utils/hooks';
import { WebtextIframe } from 'components';
import { isComponentizedPreviewEnabledFor } from 'utils';

const Preview: React.FC = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const verified = useAppSelector(selectVerified);
	const schoolId = useAppSelector(selectSchool).id;

	const { textbookId } = useParams<{ textbookId?: string }>();
	const { data: textbooks, isSuccess: isTextbooksReady } = useGetTextbooksQuery();
	const { data: user } = useGetUserInfoQuery();
	const { data: textbookReviewUrl, isLoading: isLoadingReviewUrl } = useGetTextbookReviewUrlQuery(
		Number(textbookId)
	);

	/**
	 * SUPPORT FOR NON COMPONENTIZED PREVIEW
	 **/
	const windowSize = useWindowSize();
	const containerRef = useRef(document.getElementById('nav-bar-content'));
	const [spacingToNegate, setSpacingToNegate] = useState(0);

	// Recalculate iframe size so it fills remaining screen space
	useEffect(() => {
		containerRef.current = document.getElementById('nav-bar-content');
		if (containerRef.current) {
			setSpacingToNegate(containerRef.current.getBoundingClientRect().height);
		}
	}, [containerRef, windowSize]);
	/**
	 * SUPPORT FOR NON COMPONENTIZED PREVIEW
	 **/

	/**
	 * This page contains an iframed Webtext, we need to track events it could send us.
	 */
	useTrackExternalWebtext({ textbookId: Number(textbookId) });

	/**
	 * If we are on this page without a `textbookId` in the url we need to get the first textbook
	 * from the available list and reenter the page with that `textbookId`
	 */
	useEffect(() => {
		if (!textbookId && isTextbooksReady && textbooks) {
			history.push(`/preview/${textbooks[0].id}`);
		}
	}, [history, isTextbooksReady, textbookId, textbooks]);

	/**
	 * Update textbookId when the route changes
	 */
	useEffect(() => {
		dispatch(updateGlobalState({ webtext: Number(textbookId) }));
	}, [dispatch, textbookId]);

	/**
	 * If the user refreshes the page, we need to look at the user object and see if they are newly verified
	 */
	useEffect(() => {
		if (!verified && user?.verified) {
			dispatch(updateVerified(true));
		}
	}, [dispatch, user, verified]);

	const renderComponentizedPreview = isComponentizedPreviewEnabledFor(schoolId);

	const previewCourseId = useMemo(() => {
		return Number(textbookReviewUrl?.match(/\/courses\/(\d+)/)?.[1]);
	}, [textbookReviewUrl]);

	/**
	 * We need the review URL to be loaded before we can render the preview
	 *
	 * The review URL contains the course ID of the course that should be shown
	 * for the preview.
	 */
	if (isLoadingReviewUrl) {
		return (
			<Flex w="100%" h="100%" justifyContent="center" alignItems="center">
				<Spinner size="xl" />
			</Flex>
		);
	}

	return renderComponentizedPreview ? (
		<VStack zIndex="0" justifyContent="start" height={`calc(100vh)`} overflow="hidden">
			<NavbarContextProvider
				initThemeName={ThemeName.UNIVERSAL_VELVET}
				renderComponentizedPreview={renderComponentizedPreview}
				previewCourseId={previewCourseId}>
				<Webtext />
			</NavbarContextProvider>
			<InstructorApprovedModal />
		</VStack>
	) : (
		<VStack
			position="relative"
			zIndex="0"
			height={`calc(100vh)`}
			paddingTop={spacingToNegate}
			justifyContent="center">
			<WebtextIframe src={textbookReviewUrl || ''} />
			<InstructorApprovedModal />
		</VStack>
	);
};

export default Preview;
